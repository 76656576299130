import CardSkeleton from "./skeleton/CardSkeleton";

const Card = ({
  title,
  content,
  isLoading,
}: {
  title: string;
  content: JSX.Element;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <CardSkeleton />;
  }
  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4">
      <div className="flex flex-col bg-white">
        <div className=" border-b border-gray-100 p-4">
          <h2 className="text-sm uppercase"> {title}</h2>
        </div>
        <div className="flex flex-wrap p-4">{content}</div>
      </div>
    </div>
  );
};

export default Card;
