import { useEffect, useState } from 'react';
import storage from '../Storage';
import { useAuth } from '../context/AuthProvider';

const useLoadUserFromLocalStorage = () => {
  const [isLocalStorageLoaded, setIsLocalStorageLoaded] = useState(false);
  const { isLoggedIn, login } = useAuth();

  useEffect(() => {
    const checkLocalStorageUser = async () => {
      let user = await storage.get('user');
      if (!isLocalStorageLoaded && user) {
        login(user);
        setIsLocalStorageLoaded(true);
      }
      setIsLocalStorageLoaded(true);
    };
    checkLocalStorageUser();
  }, []);

  return { isLocalStorageLoaded };
};

export default useLoadUserFromLocalStorage;
