import { ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import AuthLayout from "./layout/AuthLayout";
import config from "../common/config/config";
import ROUTES from "../common/config/routes";
import ROLES from "../common/enums/roles";
import Page403 from "../pages/Page403";
import Page404 from "../pages/Page404";
import Login from "@/pages/auth/Login";
import { useAuth } from "../common/context/AuthProvider";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import ResetPassword from "@/pages/auth/ResetPassword";
import Utils from "@/common/utils/Utils";

interface PrivateRouteProps {
  allowedRoles: number[];
  children: ReactNode;
}

interface PublicRouteProps {
  children: ReactNode;
  isLoggedIn: boolean;
}

function PrivateRoute({ allowedRoles, children }: PrivateRouteProps) {
  const { isLoggedIn, loginData } = useAuth();

  if (!config.auth) {
    return <>{children}</>;
  }

  const isAllowed = Utils.hasPermission(loginData?.data?.roles, allowedRoles);

  if (!isAllowed && isLoggedIn) {
    return <Navigate to="/403" />;
  }

  return isAllowed ? <>{children}</> : <Navigate to="/login" />;
}

function PublicRoute({ children, isLoggedIn }: PublicRouteProps) {
  return isLoggedIn ? <Navigate to="/hunting-cards" /> : <>{children}</>;
}

const Router = () => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  return (
    <>
      <Routes location={location} key={location.key}>
        <Route element={<AuthLayout />}>
          <Route
            path="/login"
            element={
              <PublicRoute isLoggedIn={isLoggedIn}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute isLoggedIn={isLoggedIn}>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute isLoggedIn={isLoggedIn}>
                <ResetPassword />
              </PublicRoute>
            }
          />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="*" element={<Navigate to="/404" />} />
          <Route index element={<Navigate to="/home" />} />

          {ROUTES.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute allowedRoles={route.allowedRoles}>
                    {route.component}
                  </PrivateRoute>
                }
              />
            );
          })}

          <Route
            path="/404"
            element={
              <PrivateRoute allowedRoles={ROLES.all()}>
                <Page404 />
              </PrivateRoute>
            }
          />

          <Route
            path="/403"
            element={
              <PrivateRoute allowedRoles={ROLES.all()}>
                <Page403 />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
