import { axios } from "../config/axios";
import config from "../config/config";
import storage from "../Storage";

export const AuthService = {
  async login(data: { email: string; password: string; remember_me: boolean }) {
    const response = await axios.post(`${config.baseUrl}/login`, data);
    storage.set("user", response.data);
    return response;
  },
  async forgot(data: { email: string }) {
    const response = await axios.post(
      `${config.baseUrl}/forgot-password`,
      data,
    );
    return response;
  },
  async reset(data: {
    email: string | null;
    token: string | null;
    password: string;
    password_confirmation: string;
  }) {
    const response = await axios.post(`${config.baseUrl}/reset-password`, data);
    return response;
  },
  async logout() {
    const response = await axios.post(`${config.baseUrl}/logout`);
    storage.remove("user");
    return response;
  },
};
