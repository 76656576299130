const TableSkeleton = () => {
  return (
    <div className="animate-pulse bg-white">
      <table className="w-full table-auto text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="p-3">
              <div className="h-5 w-3/4 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </th>
            <th className="p-3">
              <div className="h-5 w-2/3 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </th>
            <th className="p-3">
              <div className="h-5 w-4/5 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </th>
            <th className="p-3">
              <div className="h-5 w-1/2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {new Array(23).fill(0).map((element, index) => {
            return (
              <tr
                className="border-b border-gray-100 bg-white text-xs"
                key={index}
              >
                <td className="p-3">
                  <div
                    className={`h-5 w-4/5 rounded-full bg-gray-100 dark:bg-gray-700 ${index % 2 === 0 ? "w-4/5" : "w-3/5"}`}
                  ></div>
                </td>
                <td className="p-3">
                  <div
                    className={`h-5 w-4/5 rounded-full bg-gray-100 dark:bg-gray-700 ${index % 2 === 0 ? "w-2/5" : "w-3/5"}`}
                  ></div>
                </td>
                <td className="p-3">
                  <div
                    className={`h-5 w-4/5 rounded-full bg-gray-100 dark:bg-gray-700 ${index % 2 === 0 ? "w-4/5" : "w-3/5"}`}
                  ></div>
                </td>
                <td className="p-3">
                  <div
                    className={`h-5 w-4/5 rounded-full bg-gray-100 dark:bg-gray-700 ${index % 2 === 0 ? "w-1/2" : "w-4/5"}`}
                  ></div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
