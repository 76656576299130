import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { HunterData, IHuntingOrder } from "@/common/types/HuntingOrders";
import Table from "@/components/table/Table";
import { huntingCardOrderCreateFields } from "./hunting-card-orders-fields";
import FormFactory from "@/components/forms/FormFactory";
import { createHunterFields, tableColumns } from "./hunter-fields";
import { CreateHunterSchema } from "@/common/schemas/Hunting";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { HuntingCardOrderStatus } from "@/common/enums/hunting";
import { FiSave } from "react-icons/fi";
import { BsSend } from "react-icons/bs";
import { RxValueNone } from "react-icons/rx";
import FormUtils from "@/common/utils/FormUtils";
import Modal from "../../../../components/Modal";
import { TiPlusOutline } from "react-icons/ti";
import { CgTrash } from "react-icons/cg";
import ConfirmationPopover from "@/components/ConfirmationPopover";
import { FormikProps } from "formik";
import Utils from "@/common/utils/Utils";
import { GrDocumentDownload } from "react-icons/gr";

const HuntingCardOrdersEdit = () => {
  const [data, setData] = useState<IHuntingOrder>();
  const [hunter, setHunter] = useState<HunterData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<IHuntingOrder>>(null);
  const hunterFormRef = useRef<FormikProps<HunterData>>(null);
  const hunterEditFormRef = useRef<FormikProps<HunterData>>(null);
  const isOrderSent =
    data?.status === HuntingCardOrderStatus.Sent ||
    data?.status === HuntingCardOrderStatus.Accepted;

  const getHuntingCardData = async () => {
    const response = await HuntingCardOrdersService.one(id);

    const data = response?.data?.data;

    formRef.current?.setValues({
      ...data,
      association: data?.organization?.association?.name ?? "",
      organization: data?.organization?.name,
      user_note: data?.user_note ?? "",
    });

    setData(data);
  };

  useEffect(() => {
    getHuntingCardData();
  }, []);

  const onSubmit = async (values: IHuntingOrder) => {
    try {
      setIsLoading(true);
      const { statement, authorization, payment_slip, user_note } = values;
      const formData = new FormData();

      FormUtils.appendFilesToRequest([statement], formData, "statement");
      FormUtils.appendFilesToRequest(
        [authorization],
        formData,
        "authorization",
      );
      FormUtils.appendFilesToRequest([payment_slip], formData, "payment_slip");

      formData.append("user_note", user_note);

      formData.append("_method", "PATCH");

      await HuntingCardOrdersService.update(id, formData);

      // navigate(-1);
    } finally {
      setIsLoading(false);
    }
  };

  const onComplete = async () => {
    await HuntingCardOrdersService.send(id);

    navigate("/hunting-card-orders");
  };

  const onCreateHunter = async (values: HunterData) => {
    await HuntingCardOrdersService.createHunter(id, {
      ...values,
      hunting_card_type: Number(values.hunting_card_type),
    });

    getHuntingCardData();
    setOpenCreateModal(false);
  };

  const onEditHunter = async (data?: HunterData) => {
    await HuntingCardOrdersService.updateHunter(id, hunter?.id, data);
    setOpenEditModal(false);
    getHuntingCardData();
  };

  const onDeleteHunter = async (hunterId?: string) => {
    await HuntingCardOrdersService.deleteHunter(id, hunterId);
    getHuntingCardData();
  };

  const onPrint = async (hunter: HunterData) => {
    setIsLoading(true);
    const response = await HuntingCardOrdersService.getHunterPdf(
      id,
      hunter?.id,
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const onDelete = async () => {
    await HuntingCardOrdersService.delete(id);
    navigate(`./../../`);
  };

  const onDocumentDownload = async () => {
    setIsLoading(true);
    const response = await HuntingCardOrdersService.getAllHunterPdf(id);

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  return (
    <PageLayout
      backUrl="./../../"
      title="Pregled zahteva"
      buttons={
        <>
          {!isOrderSent && (
            <Button
              variant="secondary"
              onClick={onComplete}
              isLoading={isLoading}
            >
              Završi i pošalji zahtev <BsSend className="ml-3" />
            </Button>
          )}
          <ConfirmationPopover
            title="Da li ste sigurni? Zahtev će biti obrisan."
            onDelete={onDelete}
            button={
              <div>
                <Button
                  variant="secondary"
                  isLoading={isLoading}
                  hide={!(data?.status === HuntingCardOrderStatus.Open)}
                >
                  Obriši zahtev <CgTrash className="ml-3" />
                </Button>
              </div>
            }
          />
          <Button
            type="button"
            onClick={() => formRef?.current?.handleSubmit()}
            isLoading={isLoading}
            hide={isOrderSent}
          >
            Sačuvaj
            <FiSave className="ml-3" />
          </Button>
        </>
      }
    >
      <div className="space-y-4">
        <FormFactory
          autoSubmit={true}
          formFields={huntingCardOrderCreateFields(isOrderSent)}
          onSubmit={onSubmit}
          formRef={formRef}
        />
        <div className="flex items-center justify-between">
          <h1 className="text-md">Lovci</h1>
          <div className="flex items-center space-x-2">
            <Modal
              open={openCreateModal}
              setOpen={setOpenCreateModal}
              triggerButton={
                <Button isLoading={isLoading} hide={isOrderSent}>
                  Dodaj lovca
                  <TiPlusOutline className="ml-3" />
                </Button>
              }
              headerTitle="Dodaj lovca"
              buttonTitle="Dodaj"
              onSubmit={() => hunterFormRef?.current?.handleSubmit()}
              content={
                <FormFactory
                  formFields={createHunterFields()}
                  onSubmit={onCreateHunter}
                  formRef={hunterFormRef}
                  validationSchema={CreateHunterSchema}
                />
              }
            />
            <Button
              variant="secondary"
              onClick={onDocumentDownload}
              isLoading={isLoading}
            >
              Preuzmi dokument
              <GrDocumentDownload className="ml-3 text-text" />
            </Button>
          </div>
        </div>
        {data?.hunters.length === 0 ? (
          <div className="flex items-center justify-center  rounded-lg border border-gray-200 p-4">
            <RxValueNone size={20} className="text-gray-600" />
            <p className="ml-1 text-xs text-gray-500">Nema dodatih lovaca</p>
          </div>
        ) : (
          <Table
            data={{ data: data?.hunters }}
            columns={tableColumns(data?.status)}
            onPrint={onPrint}
            onDelete={(id) => onDeleteHunter(id)}
            onEdit={(item) => {
              setHunter(item);
              setOpenEditModal(true);
            }}
            hideDelete={data?.status === HuntingCardOrderStatus.Accepted}
          />
        )}
      </div>
      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni lovca"
        buttonTitle="Izmeni"
        onSubmit={() => hunterEditFormRef?.current?.handleSubmit()}
        content={
          <FormFactory
            formFields={createHunterFields(hunter)}
            onSubmit={(data) => onEditHunter(data)}
            formRef={hunterEditFormRef}
            validationSchema={CreateHunterSchema}
          />
        }
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersEdit;
