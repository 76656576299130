import { Outlet } from "react-router";
import Sidebar from "../Sidebar";
import Header from "../Header";

const AppLayout = () => {
  return (
    <main className="flex h-screen w-screen bg-gray-50">
      <Sidebar />
      <div className="flex w-full flex-col overflow-hidden">
        <div className="w-auto">
          <Header />
        </div>
        <div className="flex-1 overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default AppLayout;
