import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router";
import { Button } from "../ui/button";
import { TiPlusOutline } from "react-icons/ti";

interface PageLayoutProps {
  title: string;
  buttons?: JSX.Element;
  children: React.ReactNode;
  backUrl?: string | number;
  showCreateButton?: boolean;
}

const PageLayout = ({
  title,
  children,
  buttons,
  backUrl,
  showCreateButton,
}: PageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <main>
      <header className="flex flex-wrap items-center justify-between border-b border-gray-100 bg-white px-4 py-4 sm:h-16 sm:py-0">
        <div className="flex items-center">
          {backUrl && (
            <div
              onClick={() => navigate(backUrl.toString())}
              className="cursor-pointer p-2 pl-0 transition-all duration-300 hover:text-primary"
            >
              <IoMdArrowBack />
            </div>
          )}

          <h1 className="text-md">{title}</h1>
        </div>
        <div className=" flex items-center space-x-2 ">
          {buttons}
          {showCreateButton && (
            <Button type="button" onClick={() => navigate("create")}>
              Kreiraj
              <TiPlusOutline className="ml-3" />
            </Button>
          )}
        </div>
      </header>
      <section className="p-4">{children}</section>
    </main>
  );
};

export default PageLayout;
