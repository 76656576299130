import config from "../config/config";
import { axios } from "../config/axios";
import { OrganizationData } from "../types/Organizations";

export const OrganizationsService = {
  async all(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/organizations?${query}`,
    );

    return response;
  },

  async one(id?: string) {
    const response = await axios.get(`${config.baseUrl}/organizations/${id}`);

    return response;
  },

  async all_dropdown(associationId?: number | string) {
    const response = await axios.get(
      `${config.baseUrl}/organizations/all?association_id=${associationId ?? ""}`,
    );

    return response;
  },

  async all_with_warehouse() {
    const response = await axios.get(
      `${config.baseUrl}/organizations/with-warehouse`,
    );

    return response;
  },

  async create(data: OrganizationData) {
    const response = await axios.post(`${config.baseUrl}/organizations`, data);

    return response;
  },

  async update(id?: string, data?: OrganizationData) {
    const response = await axios.patch(
      `${config.baseUrl}/organizations/${id}`,
      data,
    );

    return response;
  },

  async delete(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/organizations/${id}`,
    );

    return response;
  },
};
