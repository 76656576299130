const TableUtils = {
  handleColumnKeys(data: any, keys: string | string[]) {
    if (Array.isArray(keys)) {
      return keys.reduce((acc: any, key: any) => {
        if (acc && acc[key]) {
          return acc[key];
        } else {
          return undefined;
        }
      }, data);
    } else {
      return data[keys];
    }
  },
};

export default TableUtils;
