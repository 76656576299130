import { Field, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../common/context/AuthProvider";
import { AuthService } from "../../common/API/AuthService";
import { Button } from "@/components/ui/button";
import { ResetPasswordSchema } from "@/common/schemas/Auth";
import PasswordInput from "@/components/forms/PasswordInput";
import { ResetPasswordValues } from "@/common/types/Auth";

const ResetPassword = () => {
  const [isLoading, setisLoading] = useState(false);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formRef = useRef<FormikProps<ResetPasswordValues>>(null);

  const handleSubmit = async (values: ResetPasswordValues) => {
    try {
      setisLoading(true);
      const response = await AuthService.reset(values);

      if (response?.data) {
        navigate("/login");
      }
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/hunting-cards");
  }, [isLoggedIn]);

  return (
    <main className="flex flex-col items-center bg-white px-8 py-4 lg:shadow-md">
      <p className="mb-2 text-lg">Resetovanje lozinke</p>
      <Formik
        innerRef={formRef}
        initialValues={{
          email: searchParams.get("email"),
          password: "",
          password_confirmation: "",
          token: searchParams.get("token"),
        }}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form className="space-y-6">
          <Field
            name="password"
            placeholder="Nova lozinka"
            label="Nova lozinka"
            component={PasswordInput}
            focus={true}
          />
          <Field
            name="password_confirmation"
            placeholder="Ponovi lozinku"
            label="Ponovljena lozinka"
            component={PasswordInput}
          />
          <Button type="submit" className="min-w-72" isLoading={isLoading}>
            Resetuj lozinku
          </Button>
        </Form>
      </Formik>
    </main>
  );
};

export default ResetPassword;
