import {
  WarehouseHuntingCard,
  WarehouseHuntingCardData,
} from "@/common/types/Warehouse";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import Table from "./table/Table";
import { tableColumns } from "@/pages/cms/warehouse/hunting-cards/warehouse-hunting-cards-fields";
import { Role } from "@/common/types/User";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";

const HuntingCardCollapsible = ({
  data,
  onDelete,
  onEdit,
  roles,
}: {
  data: WarehouseHuntingCardData[];
  onDelete: (id: string) => void;
  onEdit: (data: WarehouseHuntingCard) => void;
  roles?: Role[];
}) => {
  return (
    <Accordion type="multiple" className="space-y-4" defaultValue={["0"]}>
      {data?.map((element, index) => (
        <AccordionItem value={String(index)} key={index}>
          <AccordionTrigger>{element?.season}</AccordionTrigger>
          <AccordionContent className="p-2">
            <Table
              data={{
                data: element?.warehouse_cards,
              }}
              columns={tableColumns}
              onDelete={onDelete}
              onEdit={onEdit}
              hideEdit={
                !Utils.hasPermission(roles, [ROLES.Superadmin, ROLES.Moderator])
              }
              hideDelete={
                !Utils.hasPermission(roles, [ROLES.Superadmin, ROLES.Moderator])
              }
              hidePagination={true}
              hidePrint={true}
            />
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default HuntingCardCollapsible;
