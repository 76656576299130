import config from "../config/config";
import { axios } from "../config/axios";

export const ReportsService = {
  async all_hunting_cards(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/reports/hunting-cards?${query}`,
    );

    return response;
  },

  async all_hunting_grounds(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/reports/hunting-grounds?${query}`,
    );

    return response;
  },

  async hunting_plan_pdf(
    query?: string,
    data?: { hunting_ground_ids: number[] | string },
  ) {
    const response = await axios.post(
      `${config.baseUrl}/reports/hunting-plan-pdf?${query}`,
      data,
    );

    return response;
  },

  async all_compensations(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/reports/compensations?${query}`,
    );

    return response;
  },

  async get_statistics(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/reports/hunting-management?${query}`,
    );

    return response;
  },
};
