import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { BsUpload } from "react-icons/bs";
import { CgTrash } from "react-icons/cg";
import InputRequiredTooltip from "./InputRequiredTooltip";

type FIleUploadType = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  className?: string;
  name?: string;
  label?: string;
  required?: boolean;
  accept?: string;
  disabled?: boolean;
};

type FileUploadFormProps = FieldProps<any> & FIleUploadType;

const FileUpload = ({
  field,
  form,
  className,
  name,
  label,
  required,
  accept,
  disabled,
  ...props
}: FileUploadFormProps) => {
  const [preview, setPreview] = useState(null);
  const error = form?.errors?.[field?.name!];

  useEffect(() => {
    // Generate a preview whenever a new file is selected
    if (field.value && !field.value?.url) {
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(field.value);
    } else {
      setPreview(null);
    }
  }, [field.value]);

  const handleRemove = () => {
    form.setFieldValue(field?.name, undefined);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event?.dataTransfer?.files;
    if (files?.length! > 0) {
      const file = files[0];

      if (file?.type === "application/pdf" || file?.type.includes("image/")) {
        form.setFieldValue(field?.name, file);
      }
    }
  };

  function bytesToMB(bytes: number) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  return (
    <section className={`relative space-y-1 ${className}`}>
      <div className={`flex flex-col ${className}`}>
        {label && (
          <div className="flex flex-row items-center space-x-1">
            <label className="mb-2 block text-xs text-label">{label}</label>
            {required && <InputRequiredTooltip label="Polje je obavezno" />}
          </div>
        )}

        {field.value?.url && (
          <div className="mb-2 flex items-center justify-between rounded-lg border border-gray-300 p-4">
            <a
              target="_blank"
              href={field?.value?.url}
              className="flex items-center gap-x-3"
            >
              <span className="flex size-8 items-center justify-center rounded-lg border border-gray-200 text-sm text-gray-500">
                <BsUpload />
              </span>
              <div>
                <div className="text-xs font-medium text-gray-800">
                  {field.value?.name}
                </div>
              </div>
            </a>
            {!disabled && (
              <div
                onClick={handleRemove}
                className="inline-flex items-center gap-x-2"
              >
                <span className="cursor-pointer text-gray-500 transition-all duration-300 hover:text-red-500">
                  <CgTrash />
                </span>
              </div>
            )}
          </div>
        )}

        {preview && (
          <div className="mb-2 flex items-center justify-between rounded-lg border border-gray-300 p-4">
            <div className="flex items-center gap-x-3">
              <span className="flex size-8 items-center justify-center rounded-lg border border-gray-200 text-sm text-gray-500">
                <BsUpload />
              </span>
              <div>
                <p className="text-xs font-medium text-gray-800">
                  {field.value?.name}
                </p>
                <p className="text-xs text-gray-500">
                  {bytesToMB(field?.value?.size)} MB
                </p>
              </div>
            </div>
            <div
              onClick={handleRemove}
              className="inline-flex items-center gap-x-2"
            >
              <span className="cursor-pointer text-gray-500 transition-all duration-300 hover:text-red-500">
                <CgTrash />
              </span>
            </div>
          </div>
        )}

        {!preview && !field.value?.url && (
          <div
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
            className={`flex w-full items-center justify-center rounded-lg border border-dashed transition-all duration-300 ${error ? "border-error" : "border-gray-300 hover:border-gray-400"}`}
          >
            <label
              htmlFor={field?.name}
              className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border-dashed border-gray-300 bg-card"
            >
              <div className="flex h-full w-full items-center justify-center p-4">
                <span className="flex size-8 items-center justify-center rounded-lg border border-gray-200 text-sm text-gray-500">
                  <BsUpload />
                </span>
                <p className="ml-2 text-xs text-gray-600">
                  Prevucite, spustite ili{" "}
                  <span className="text-primary">izaberite fajl</span>{" "}
                </p>
              </div>
              <input
                id={field?.name}
                type="file"
                className="hidden"
                accept={accept}
                onChange={(e) => {
                  // Object is possibly null error w/o check
                  if (e.currentTarget.files) {
                    form.setFieldValue(field?.name, e.currentTarget.files[0]);
                  }
                }}
              />
            </label>
          </div>
        )}
      </div>
      <p className="absolute text-xs text-error">
        {error && !preview && !field.value?.url ? (error as string) : ""}
      </p>
    </section>
  );
};

export default FileUpload;
