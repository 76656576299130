const CardSkeleton = () => {
  return (
    <div className="col-span-12 animate-pulse bg-white md:col-span-6 lg:col-span-4">
      <div className="flex flex-col bg-white">
        <div className="border-b border-gray-200 p-4">
          <div className="h-5 w-44 rounded-full bg-gray-100 dark:bg-gray-700"></div>
        </div>
        <div className="space-y-3 p-3">
          <div className="h-3 w-full rounded-full bg-gray-100 dark:bg-gray-700"></div>
          <div className="h-3 w-full rounded-full bg-gray-100 dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
