import * as Yup from "yup";

export const CreateUserSchema = Yup.object().shape({
  name: Yup.string().required("Unesite ime"),
  email: Yup.string().email("Email nije validan").required("Unesite email"),
  password: Yup.string()
    .min(8, "Lozinka mora biti duža od 8 karaktera")
    .max(20, "Lozinka mora biti kraća od 20 karaktera")
    .matches(/[A-Z]/, "Lozinka mora da ima veliko slovo")
    .matches(/[a-z]/, "Lozinka mora da ima malo slovo")
    .matches(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      "Mora sadržati jedan specijalni karakter",
    )
    .required("Unesite lozinku"),
  password_confirmation: Yup.string()
    .required("Unesite lozinku ponovo")
    .oneOf([Yup.ref("password"), null!], "Lozinke moraju biti iste"),
  unique_identifier: Yup.mixed(),
  // .test(
  //   "len",
  //   "JMBG/Broj pasoša ne može da ima više od 13 karaktera",
  //   (val) => (val ? val?.toString()?.length <= 13 : false),
  // )
  // .typeError("JMBG/Broj pasoša mora biti broj")
  // .required("Unesite jmbg/broj pasoša"),
  phone: Yup.string()
    .matches(/^[0-9/+\-]+$/, "Unesite validan broj telefona")
    .required("Unesite broj telefona"),
  address: Yup.string().required("Unesite adresu"),
  residence: Yup.string().required("Unesite mesto"),
  postal_code: Yup.number()
    .typeError("Poštanski broj mora biti validan broj")
    .required("Unesite poštanski broj"),
  role_id: Yup.string(),
  association_id: Yup.string(),
  organization_id: Yup.string(),
  is_examiner: Yup.boolean(),
});

export const EditUserSchema = Yup.object().shape(
  {
    name: Yup.string().required("Unesite ime"),
    email: Yup.string().email("Email nije validan").required("Unesite email"),
    password: Yup.string().when("password", {
      is: (value: string) => value !== "",
      then: (password) =>
        password
          .min(8, "Lozinka mora biti duža od 8 karaktera")
          .max(20, "Lozinka mora biti kraća od 20 karaktera")
          .matches(/[A-Z]/, "Lozinka mora da ima veliko slovo")
          .matches(/[a-z]/, "Lozinka mora da ima malo slovo")
          .matches(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            "Mora sadržati jedan specijalni karakter",
          ),
    }),
    password_confirmation: Yup.string().when("password_confirmation", {
      is: (value: string) => value !== "",
      then: (password_confirmation) =>
        password_confirmation.oneOf(
          [Yup.ref("password"), null!],
          "Lozinke moraju biti iste",
        ),
    }),
    unique_identifier: Yup.mixed(),
    phone: Yup.number()
      .typeError("Telefon mora biti broj")
      .required("Unesite broj telefona"),
    address: Yup.string().required("Unesite adresu"),
    residence: Yup.string().required("Unesite mesto"),
    postal_code: Yup.number()
      .typeError("Poštanski broj mora biti validan broj")
      .required("Unesite poštanski broj"),
    role_id: Yup.string(),
    association_id: Yup.string(),
    organization_id: Yup.string(),
    is_examiner: Yup.boolean(),
  },
  [
    ["password", "password"],
    ["password_confirmation", "password_confirmation"],
  ],
);
