import { AnimalData, AnimalReportData } from "../types/Animals";
import { HuntingPlanRevision } from "../types/HuntingPlans";
import FormUtils from "./FormUtils";
import Utils from "./Utils";

const HuntingUtils = {
  formatRevisionData: (revisions?: HuntingPlanRevision[]) =>
    revisions?.map((item) => {
      return {
        name:
          item?.revision_number === 1
            ? "Godišnji plan"
            : `Revizija - ${Utils.getMonthString(item?.created_at)}`,
        id: item?.revision_number,
      };
    }),

  handleAnimalReportInput: (
    animal_id: number,
    name: string,
    val: number,
    type: number,
    animalData: AnimalReportData[],
  ) => {
    let newData = [];

    const existingAnimal = animalData?.find(
      (animal) => animal?.animal_id === Number(animal_id),
    );

    if (existingAnimal) {
      existingAnimal[name as keyof AnimalReportData] = val;

      if (!FormUtils.checkAreValuesEmpty(existingAnimal, "animal_id")) {
        return animalData?.filter(
          (animal) => animal?.animal_id !== Number(animal_id),
        );
      }

      return animalData;
    } else {
      let newAnimal: AnimalReportData = {
        animal_id,
      };

      type === 1
        ? (newAnimal = {
            ...newAnimal,
            male: 0,
            female: 0,
            offspring: 0,
          })
        : (newAnimal = {
            ...newAnimal,
            total: 0,
          });

      newAnimal[name as keyof AnimalReportData] = val;

      newData = [...animalData, newAnimal];

      return newData;
    }
  },
  handleAnimalPlanInput: (
    animal_id: number,
    name: string,
    val: number,
    type: number,
    animalData: AnimalData[],
  ) => {
    let newData: AnimalData[] = [];
    const existingAnimal = animalData?.find(
      (animal: AnimalData) => animal?.animal_id === Number(animal_id),
    );

    if (existingAnimal) {
      existingAnimal[name as keyof AnimalData] = val;

      if (!FormUtils.checkAreValuesEmpty(existingAnimal, "animal_id")) {
        return animalData?.filter(
          (animal: AnimalData) => animal?.animal_id !== Number(animal_id),
        );
      }

      return animalData;
    } else {
      let newAnimal: AnimalData = {
        animal_id,
        native: 0,
        optimal: 0,
        minimal: 0,
      };

      type === 1
        ? (newAnimal = {
            ...newAnimal,
            male: 0,
            female: 0,
            offspring: 0,
          })
        : (newAnimal = {
            ...newAnimal,
            total: 0,
          });

      newAnimal[name as keyof AnimalData] = val;

      newData = [...animalData, newAnimal];

      return newData;
    }
  },
};

export default HuntingUtils;
