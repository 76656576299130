import { Avatar, AvatarFallback } from "./ui/avatar";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useNavigate } from "react-router";
import { useAuth } from "@/common/context/AuthProvider";
import { AiOutlineLogout } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa6";

const HeaderAvatar = () => {
  const { loginData, logout } = useAuth();
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Avatar>
          <AvatarFallback>{loginData?.data?.name.slice(0, 1)}</AvatarFallback>
        </Avatar>
      </PopoverTrigger>
      <PopoverContent className="mr-5 flex w-[170px] flex-col space-y-2 p-2 ">
        <p
          className="flex cursor-pointer items-center justify-between p-2 text-xs transition-all duration-300 hover:bg-gray-100"
          onClick={() => navigate("/profile")}
        >
          Profil
          <FaRegUser />
        </p>
        <p
          className="flex cursor-pointer items-center justify-between p-2 text-xs text-error transition-all duration-300 hover:bg-red-100"
          onClick={onLogout}
        >
          Odjavi se
          <AiOutlineLogout />
        </p>
      </PopoverContent>
    </Popover>
  );
};

export default HeaderAvatar;
