import config from "../config/config";
import { axios } from "../config/axios";
import { UserData } from "../types/User";

export const UsersService = {
  async all(query: string) {
    const response = await axios.get(`${config.baseUrl}/users?${query}`);

    return response;
  },
  async one(id?: string) {
    const response = await axios.get(`${config.baseUrl}/users/${id}`);

    return response;
  },
  async create(data: UserData) {
    const response = await axios.post(`${config.baseUrl}/users`, data);

    return response;
  },
  async update(id?: string, data?: UserData) {
    const response = await axios.patch(`${config.baseUrl}/users/${id}`, data);

    return response;
  },
  async delete(id?: string) {
    const response = await axios.delete(`${config.baseUrl}/users/${id}`);

    return response;
  },
};
