/**
 * A utility object for interacting with the browser's local storage.
 * Provides methods to get, set, and remove data from local storage.
 *
 * @namespace Storage
 */

const storage = {
  /**
   * Retrieves a value from local storage by the specified key.
   *
   * @param {string} key - The key used to retrieve the value.
   * @param {*} [defaultVal = undefined] - The default value to return if the key is not found.
   * @returns {*} The value associated with the key, or the default value if not found.
   */
  get(key: string, defaultVal = undefined) {
    try {
      const item = localStorage.getItem(key);
      return item !== null ? JSON.parse(item) : defaultVal;
    } catch (error) {
      console.error(
        `Error parsing data from localStorage key "${key}":`,
        error,
      );
      return defaultVal;
    }
  },

  /**
   * Sets a key-value pair in local storage.
   *
   * @param {string} key - The key to set.
   * @param {*} value - The value to associate with the key.
   */
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  /**
   * Removes a key-value pair from local storage by the specified key.
   * @param {string} key - The key to remove.
   */
  remove(key: string) {
    localStorage.removeItem(key);
  },
};

export default storage;
