import { DropdownData } from "@/common/types/Form";
import Utils from "@/common/utils/Utils";
import Text from "@/components/Text";
import Dropdown from "@/components/forms/Dropdown";

export const tableColumns = [
  {
    title: "Vrsta divljači",
    dataIndex: "name",
    key: "name",
    render: ({ name, gender }: { name: string; gender: string | null }) => (
      <Text variant="table">{`${name}${gender ? `(${gender})` : ""}`}</Text>
    ),
  },
  {
    title: "Osnovna naknada po jedinki(rsd)",
    dataIndex: "price",
    key: "price",
    render: ({ price }: { price: number }) => (
      <Text variant="table">{Utils.formatNumber(price) ?? 0}</Text>
    ),
  },
  {
    title: "Planirani odstrel",
    dataIndex: "planned_hunt",
    key: "planned_hunt",
  },
  {
    title: "Vrednost odstrela(rsd)",
    dataIndex: "total_price",
    key: "total_price",
    render: ({ total_price }: { total_price: number }) => (
      <Text variant="table">{Utils.formatNumber(total_price) ?? 0}</Text>
    ),
  },
  {
    title: "Obračunata visina naknade(rsd)",
    dataIndex: "calculated_price",
    key: "calculated_price",
    render: ({ calculated_price }: { calculated_price: number }) => (
      <Text variant="table">{Utils.formatNumber(calculated_price) ?? 0}</Text>
    ),
  },
];

export const filterFields = (seasons?: DropdownData[]) => {
  return [
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Lovna sezona",
        name: "season",
        placeholder: "Izaberi lovnu sezonu",
        options: seasons ?? [],
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  season: "",
};
