import CONSTANTS from "@/common/config/constants";
import { AnimalInputType } from "@/common/enums/animals";
import { AnimalHuntingPlanRecord } from "@/common/types/Animals";
import { DropdownData } from "@/common/types/Form";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import Input from "@/components/forms/Input";
import ProgressInput from "@/components/ProgressInput";

export const tableColumns = (
  onInputChange?: (
    animal_id: number,
    name: string,
    value: number,
    type: number,
  ) => void,
  isMonthFilled?: boolean,
  isSuperAdmin?: boolean,
  isRegularAdmin?: boolean,
) => {
  return [
    {
      title: "Vrsta divljači",
      dataIndex: ["animal", "name"],
      key: ["animal", "name"],
    },
    {
      title: "Mužjak",
      dataIndex: "male_hunted",
      key: "male_hunted",
      render: ({
        animal,
        male_hunted,
        male_planned,
        male_percentage,
        male_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="male"
          total={male_planned}
          percent={male_percentage}
          percentValue={male_hunted}
          placeholder={`Fond divljači: ${male_remaining}`}
          value={isMonthFilled ? realization_for_month?.male : undefined}
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Ženka",
      dataIndex: "female_hunted",
      key: "female_hunted",
      render: ({
        animal,
        female_hunted,
        female_planned,
        female_percentage,
        female_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="female"
          total={female_planned}
          percent={female_percentage}
          percentValue={female_hunted}
          placeholder={`Fond divljači: ${female_remaining}`}
          value={isMonthFilled ? realization_for_month?.female : undefined}
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Podmladak",
      dataIndex: "offspring_hunted",
      key: "offspring_hunted",
      render: ({
        animal,
        offspring_hunted,
        offspring_planned,
        offspring_percentage,
        offspring_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="offspring"
          total={offspring_planned}
          percent={offspring_percentage}
          percentValue={offspring_hunted}
          placeholder={`Fond divljači: ${offspring_remaining}`}
          value={isMonthFilled ? realization_for_month?.offspring : undefined}
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Ukupno",
      dataIndex: "total_hunted",
      key: "total_hunted",
      render: ({
        animal,
        total_hunted,
        total_planned,
        total_percentage,
        total_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="total"
          total={total_planned}
          percent={total_percentage}
          percentValue={total_hunted}
          placeholder={`Fond divljači: ${total_remaining}`}
          value={isMonthFilled ? realization_for_month?.total : undefined}
          hide={animal.input_type === AnimalInputType.Partial}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
  ];
};

export const filterFields = (
  huntingGrounds?: DropdownData[],
  seasons?: DropdownData[],
) => {
  return [
    {
      controlType: DropdownSearch,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovište",
        name: "id",
        placeholder: "Izaberite lovište",
        value: "",
        options: huntingGrounds,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Mesec",
        name: "month",
        placeholder: "Izaberite mesec",
        value: new Date().getUTCMonth(),
        options: CONSTANTS.monthObjects,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovna sezona",
        name: "season",
        placeholder: "Izaberite lovnu sezonu",
        value: "",
        options: seasons,
        hide: seasons?.length === 0,
      },
    },
  ];
};

export const filterQueries = {
  id: "",
  month: String(new Date().getUTCMonth()),
  season: "",
};

export const huntingPlanRecordFields = (
  huntingPlanRecord?: AnimalHuntingPlanRecord,
  isMonthFilled?: boolean,
) => [
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Mužjak - planirano ${huntingPlanRecord?.male_planned ?? 0}`,
      name: "male",
      placeholder: `Fond divljači: ${huntingPlanRecord?.male_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.male ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Ženka - planirano ${huntingPlanRecord?.female_planned ?? 0}`,
      name: "female",
      placeholder: `Fond divljači: ${huntingPlanRecord?.female_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.female ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Podmladak - planirano ${huntingPlanRecord?.offspring_planned ?? 0}`,
      name: "offspring",
      placeholder: `Fond divljači: ${huntingPlanRecord?.offspring_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.offspring ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Ukupno - planirano - ${huntingPlanRecord?.total_planned ?? 0}`,
      name: "total",
      placeholder: `Fond divljači: ${huntingPlanRecord?.total_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.total ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Partial,
    },
  },
];
