import { FieldInputProps, FieldProps, FormikProps } from "formik";

type TextAreaProps = FieldProps<any> & {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

const TextArea = ({
  field,
  form,
  placeholder,
  label,
  disabled,
  className,
  ...props
}: TextAreaProps) => {
  const error = form.errors[field.name];

  return (
    <div className={`${className} relative`}>
      <label className="mb-2 block text-xs text-gray-900">{label}</label>
      <textarea
        {...field}
        {...props}
        disabled={disabled}
        className={`min-h-36 w-full rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-gray-900 focus:border-primary focus:ring-primary disabled:pointer-events-none disabled:opacity-50 ${error ? "border-error bg-red-50 text-red-900 placeholder-red-700 focus:border-error focus:ring-error" : ""}`}
        placeholder={placeholder}
      />
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default TextArea;
