import { AnimalInputType } from "@/common/enums/animals";

const StampCardItem = ({
  name,
  male,
  female,
  offspring,
  total,
  input_type,
}: {
  name: string;
  male?: number | null;
  female?: number | null;
  offspring?: number | null;
  total?: number | null;
  input_type?: number | null;
}) => {
  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-3">
      <div className="flex flex-col bg-white">
        <div className=" border-b border-gray-100 p-4">
          <h2 className="text-sm uppercase"> {name}</h2>
        </div>
        {input_type === AnimalInputType.Total ? (
          <div className="flex flex-wrap p-4">
            <div className="mr-3">
              <span className="text-xs uppercase text-gray-400">Ukupno</span>
              <h2 className="text-xl font-medium">
                {!total || total < 0 ? 0 : total}
              </h2>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap p-4">
            <div className="mr-3">
              <span className="text-xs uppercase text-gray-400">Mužjak</span>
              <h2 className="text-xl font-medium">
                {!male || male < 0 ? 0 : male}
              </h2>
            </div>
            <div className="mr-3">
              <span className="text-xs uppercase text-gray-400">Ženka</span>
              <h2 className="text-xl font-medium">
                {!female || female < 0 ? 0 : female}
              </h2>
            </div>
            <div className="mr-3">
              <span className="text-xs uppercase text-gray-400">Podmladak</span>
              <h2 className="text-xl font-medium">
                {!offspring || offspring < 0 ? 0 : offspring}
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StampCardItem;
