import { useAuth } from "@/common/context/AuthProvider";
import { UserData } from "@/common/types/User";
import FormFactory from "@/components/forms/FormFactory";
import PageLayout from "@/components/layout/PageLayout";
import { FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { profileFields } from "./profile-fields";
import ROLES from "@/common/enums/roles";

const ProfileEdit = () => {
  const { loginData } = useAuth();
  const userData = loginData?.data;
  const formRef = useRef<FormikProps<any>>(null);

  const onSubmit = async (values: UserData) => {};

  useEffect(() => {
    formRef?.current?.setValues({
      ...userData,
      role_id: userData?.roles?.find((role) => role?.id != ROLES.Examiner)
        ?.name,
      association_id: userData?.association?.name,
      organization_id: userData?.organization?.name,
      password: "",
      password_confirmation: "",
      is_examiner:
        userData?.roles?.filter((role) => role?.id === ROLES.Examiner)
          ?.length !== 0,
    });
  }, []);

  return (
    <PageLayout title="Profil" backUrl="./../">
      <FormFactory
        formFields={profileFields(
          userData?.roles?.find((role) => role?.id != ROLES.Examiner)?.id,
        )}
        onSubmit={onSubmit}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default ProfileEdit;
