import { createContext, PropsWithChildren, useContext, useState } from "react";
import storage from "../Storage";
import { IUser } from "../types/User";
import Utils from "../utils/Utils";
import ROLES from "../enums/roles";

interface LoginContextType {
  isLoggedIn: boolean;
  loginData: ILoginResponse | null;
  isAdmin: boolean | undefined;
  isUser: boolean | undefined;
  login: (loginData: ILoginResponse) => void;
  logout: () => void;
}

export interface ILoginResponse {
  message: string;
  token: string;
  expires_in: number;
  data: IUser;
}

const LoginContext = createContext<LoginContextType | null>(null);

export const useAuth = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLogin must be used within a LoginProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<ILoginResponse | null>(null);

  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [ROLES.Admin]);

  const isUser = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.User,
    ROLES.Agency,
  ]);

  const login = (data: ILoginResponse) => {
    setIsLoggedIn(true);
    setLoginData(data);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setLoginData(null);
    storage.remove("user");
    storage.remove("selectedRows");
    storage.remove("all");
  };

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        loginData,
        isAdmin,
        isUser,
        login,
        logout,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
