import { DropdownData } from "@/common/types/Form";
import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-reports-fields";
import { HuntingCardReport } from "@/common/types/Reports";
import { ReportsService } from "@/common/API/ReportsService";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";

const HuntingCardReportsList = () => {
  const [data, setData] = useState<HuntingCardReport>();
  const [seasons, setSeasons] = useState<DropdownData[]>([]);

  const { loginData, isAdmin } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations } = useDropdownData({ hasAgency: true, query });

  const getHuntingCardReport = async () => {
    const response = await ReportsService.all_hunting_cards(
      Utils.formatQueryString(query),
    );

    const availableSeasons: DropdownData[] =
      response?.data?.data?.available_seasons.map((season: string) => ({
        name: season,
        id: season,
      }));

    if (query?.season && query?.season === "") {
      const season = availableSeasons?.[0]?.id ?? "";

      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        season: prevState?.season || String(season),
      }));
    }

    setData(response?.data?.data?.report);
    setSeasons(availableSeasons);
  };

  useEffect(() => {
    isAdmin &&
      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        association_id: String(loginData?.data?.association?.id),
      }));
  }, []);

  useEffect(() => {
    if (query?.association_id) {
      getHuntingCardReport();
    }
  }, [query]);

  return (
    <PageLayout title={`Izveštaji za lovne karte`}>
      <Table
        data={{ data: data?.organizations }}
        columns={tableColumns(data)}
        filterFields={filterFields(associations, seasons)}
        filterQueries={filterQueries}
        hidePagination={true}
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
      />
    </PageLayout>
  );
};

export default HuntingCardReportsList;
