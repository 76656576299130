import { SidebarChild } from "@/common/types/Sidebar";
import { useLocation, useNavigate } from "react-router";

const SidebarChildItem = ({ route, label, icon }: SidebarChild) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`flex cursor-pointer select-none flex-row items-center gap-x-2 px-8 py-3 transition-all duration-300 hover:bg-sidebarHoverItem ${location?.pathname?.includes(route) ? "border-l-4 border-primary bg-sidebarActiveItem text-text" : "border-l-4 border-sidebarBackground"}`}
      onClick={() => navigate(route)}
    >
      {icon && (
        <div
          className={`font-xs text-sm ${location?.pathname?.includes(route) ? "text-primary" : "text-sidebarText"}`}
        >
          {icon}
        </div>
      )}
      <div
        className={`font-xs text-sm text-sidebarText ${location?.pathname.includes(route) ? "text-text" : ""}`}
      >
        {label}
      </div>
    </div>
  );
};

export default SidebarChildItem;
