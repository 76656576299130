import * as Yup from "yup";

export const CreateStampsSchema = Yup.object().shape({
  association_id: Yup.string().required("Izaberite savez"),
  animal_id: Yup.string().required("Izaberite vrstu divljači"),
  stamp_type: Yup.string().required("Izaberite tip markice"),
  from_number: Yup.number().required("Unesite broj"),
  to_number: Yup.number().required("Unesite broj"),
});

export const CreateHuntingCardsSchema = Yup.object().shape({
  card_type: Yup.string().required("Izaberite tip lovne karte"),
  from_number: Yup.number()
    .typeError("Unesite ispravan broj")
    .required("Unesite broj"),
  to_number: Yup.number()
    .typeError("Unesite ispravan broj")
    .required("Unesite broj"),
});
