import axiosInstance, { AxiosError, AxiosResponse } from "axios";
import config from "./config";
import Toast from "../Toast";
import storage from "../Storage";

export enum ApiErrorReason {
  BLOCKED = 1,
  NOT_APPROVED = 2,
  NOT_VERIFIED_PHONE = 3,
  DELETED = 4,
}

export type ApiResponse<T = any> = AxiosResponse<{
  data: T;
  message?: string;
  token?: string;
}>;

export type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
  total_pages: number;
};

export type PaginatedApiResponse<T = any> = AxiosResponse<{
  data: T;
  pagination: {
    total: number;
    per_page: number;
    current_page: number;
    total_pages: number;
  };
  message?: string;
  token?: string;
}>;

export type ApiErrorResponse = AxiosError<{
  message: string;
  reason?: ApiErrorReason;
  errors?: any;
}>;

// Creating axios instance for routes that are api protected
export const axios = axiosInstance.create({
  baseURL: config.baseUrl,
  headers: {
    "Content-Type": "application/json",
    "X-Client-Locale": "sr",
  },
});

/*
    Add a request interceptor to add authentication token on each request
*/
axios.interceptors.request.use(
  async function (config) {
    const token = storage.get("user")?.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

/*
    Add a response interceptor to handle errors
*/
axios.interceptors.response.use(
  (response): ApiResponse => {
    if (response?.data?.message) {
      Toast.success(response?.data?.message);
    }

    return response;
  },
  async (error) => {
    error = error?.response;
    const token = storage.get("user")?.token;

    switch (error?.status) {
      case 401:
        if (token) {
          Toast.error("Niste autorizovani za ovu akciju");
          storage.remove("user");
          window.location.reload();
        }
        break;
      case 403:
        Toast.error(error.data.message || "Nemate pristup");
        break;
      case 404:
        Toast.error(
          "Trazeni resurs nije pronadjen. Molim vaš pokušajete ponovo",
        );
        break;
      case 419:
        Toast.error("Istekla je sesija. Ulogujte se ponovo");
        storage.remove("user");
        break;
      case 422:
        Toast.error(error.data.message || "Niste autorizovani za ovu akciju");
        return Promise.reject({ success: false, error });
      case 429:
        Toast.warn("Šaljete previše poziva. Pokušajte ponovo kasnije");
        return Promise.reject({ success: false, error });
      case 500:
        Toast.error("Neočekivana greška. Pokušajte ponovo kasnije.");
        break;
      case 503:
        Toast.warn("Došlo je do greške. Pokušajte ponovo kasnije");
        break;
      default:
        Toast.error(
          error?.data?.message ||
            "Neočekivana greška. Pokušajte ponovo kasnije.",
        );
    }

    return Promise.reject(error);
  },
);
