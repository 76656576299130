import { useNavigate, useParams } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useEffect, useRef, useState } from "react";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { huntingGroundsFields } from "./hunting-grounds-fields";
import { HuntingGround } from "@/common/types/HuntingGrounds";
import { CreateHuntingGroundsSchema } from "@/common/schemas/HuntingGrounds";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import { useAuth } from "@/common/context/AuthProvider";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";
import useDropdownData from "@/common/hooks/useDropdownData";

const HuntingGroundsEdit = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { organizations } = useDropdownData({ hasAgency: false });

  const formRef: React.MutableRefObject<any> = useRef(null);

  const getHuntingGroundData = async () => {
    const response = await HuntingGroundsService.one(id);
    const data = response?.data?.data;

    formRef?.current?.setValues({
      name: data?.name,
      organization_id: String(data?.organization?.id) ?? "",
      surface_area: data?.surface_area ?? "",
    });
  };

  useEffect(() => {
    getHuntingGroundData();
  }, [organizations]);

  const onSubmit = async (values: HuntingGround) => {
    try {
      setIsLoading(true);
      await HuntingGroundsService.update(id, values);

      navigate(`/hunting-grounds`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      backUrl="./../"
      title="Izmeni lovište"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
          hide={!isAdmin}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={huntingGroundsFields(organizations)}
        onSubmit={onSubmit}
        validationSchema={CreateHuntingGroundsSchema}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default HuntingGroundsEdit;
