import { useNavigate } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useEffect, useRef, useState } from "react";
import { UsersService } from "@/common/API/UsersService";
import { UserData } from "@/common/types/User";
import { usersAddFields } from "./users-fields";
import { CreateUserSchema } from "@/common/schemas/Users";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";
import useDropdownData from "@/common/hooks/useDropdownData";

const UsersAdd = () => {
  const [role, setRole] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { associations, organizations, getOrganizations } = useDropdownData({
    hasAgency: false,
  });

  const navigate = useNavigate();
  const formRef = useRef<FormikProps<UserData>>(null);
  const isPasswordRequired = true;

  const onRoleChange = (role?: string) => {
    setRole(Number(role));
  };

  useEffect(() => {
    formRef?.current?.setFieldValue("organization_id", "");
  }, [organizations]);

  useEffect(() => {
    formRef?.current?.setFieldValue("association_id", "");
    formRef?.current?.setFieldValue("organization_id", "");

    Number(role) === ROLES.Agency ? getOrganizations("-1") : getOrganizations();
  }, [role]);

  const onSubmit = async (values: UserData) => {
    try {
      setIsLoading(true);
      const { role_id, is_examiner, association_id, ...rest } = values;
      const roleIds = role_id ? [Number(role_id)] : [];

      if (is_examiner) {
        roleIds.push(ROLES.Examiner);
      }

      const data = {
        ...rest,
        role_ids: roleIds,
        association_id: Number(association_id) === -1 ? null : association_id,
      };

      await UsersService.create(data);
      navigate(`/users`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      title="Kreiraj korisnika"
      backUrl="./../"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={usersAddFields(
          associations,
          organizations,
          role,
          onRoleChange,
          getOrganizations,
          isPasswordRequired,
        )}
        onSubmit={onSubmit}
        validationSchema={CreateUserSchema}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default UsersAdd;
