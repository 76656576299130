import { useQuery } from "@/common/hooks/useQuery";
import PageLayout from "@/components/layout/PageLayout";
import TableFilterForm from "@/components/table/TableFilterForm";
import { useEffect, useRef, useState } from "react";
import {
  filterFields,
  filterQueries,
  warehouseStampsAddFields,
  warehouseStampsEditFields,
} from "./warehouse-stamps-fields";
import { DropdownData } from "@/common/types/Form";
import { WarehouseStampsService } from "@/common/API/WarehouseStampsService";
import Utils from "@/common/utils/Utils";
import {
  WarehouseStamp,
  WarehouseStampCreateData,
  WarehouseStampData,
} from "@/common/types/Warehouse";
import StampCollapsible from "@/components/StampCollapsible";
import { Button } from "@/components/ui/button";
import { TiPlusOutline } from "react-icons/ti";
import Modal from "@/components/Modal";
import FormFactory from "@/components/forms/FormFactory";
import { CreateStampsSchema } from "@/common/schemas/Warehouse";
import { AnimalsService } from "@/common/API/AnimalsService";
import { StampType } from "@/common/enums/stamps";
import { RxValueNone } from "react-icons/rx";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";
import useDropdownData from "@/common/hooks/useDropdownData";

const WarehouseStampsList = () => {
  const [stamps, setStamps] = useState<WarehouseStampData[]>([]);
  const [animals, setAnimals] = useState<DropdownData[]>([]);
  const [animalStampTypes, setAnimalStampTypes] = useState<DropdownData[]>([]);
  const [animalId, setAnimalId] = useState<string>("");
  const [editStamp, setEditStamp] = useState<WarehouseStamp | null>(null);
  const [lastAvailableStamp, setAvailableStamp] = useState<number>(0);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const { loginData, isAdmin } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations } = useDropdownData({
    hasAgency: false,
    query,
  });

  const filtersFormRef = useRef<FormikProps<any>>(null);
  const stampsFormRef = useRef<FormikProps<WarehouseStampCreateData>>(null);
  const stampsEditFormRef = useRef<FormikProps<WarehouseStampCreateData>>(null);

  const getStamps = async () => {
    const response = await WarehouseStampsService.all(
      Utils.formatQueryString(query),
    );

    setStamps(response?.data?.data?.warehouse_stamps);
  };

  const getAnimals = async () => {
    const response = await AnimalsService.allWithStamps();

    setAnimals(response?.data?.data);
  };

  const getAnimalStamps = async (animal_id: string) => {
    const response = await AnimalsService.oneWithStamps(animal_id);
    const stamps: number[] = response?.data?.data?.stamp_types?.stamp_types;

    const formatStampData = stamps?.map((stamp) => ({
      name: stamp === StampType?.Twopart ? "Dvodelna" : "Trodelna",
      id: stamp,
    }));

    setAnimalStampTypes(formatStampData);
    setAnimalId(animal_id);
    setAvailableStamp(0);
  };

  const getLastStampNumber = async (stamp_type: string) => {
    const response = await WarehouseStampsService.getLastNumber(
      animalId,
      stamp_type,
    );

    setAvailableStamp(response?.data?.data);
  };

  useEffect(() => {
    getAnimals();

    isAdmin &&
      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        association_id: String(loginData?.data?.association?.id),
      }));
  }, []);

  useEffect(() => {
    if (query?.association_id) {
      getStamps();

      for (let queryKey in filterQueries) {
        filtersFormRef?.current?.setFieldValue(queryKey, query[queryKey]);
      }
    }
  }, [query]);

  useEffect(() => {
    stampsFormRef?.current?.setFieldValue("stamp_type", "");
    stampsEditFormRef?.current?.setFieldValue("stamp_type", "");
  }, [animalId]);

  const onAddStamps = async (data: WarehouseStampCreateData) => {
    await WarehouseStampsService.create(data);

    getStamps();
    setOpenCreateModal(false);
  };

  const onDeleteStamp = async (id: string) => {
    await WarehouseStampsService.delete(id);

    getStamps();
  };

  const onEditStamp = async (values: WarehouseStampCreateData) => {
    await WarehouseStampsService.update(editStamp?.id, values);

    getStamps();
    setOpenEditModal(false);
  };

  return (
    <PageLayout
      title="Magacin markica"
      buttons={
        query?.association_id ? (
          <Modal
            open={openCreateModal}
            setOpen={setOpenCreateModal}
            triggerButton={
              <Button
                onClick={() => {
                  setAnimalStampTypes([]);
                  setAvailableStamp(0);
                }}
                hide={
                  !Utils.hasPermission(loginData?.data?.roles, [
                    ROLES.Superadmin,
                    ROLES.Moderator,
                  ])
                }
              >
                Dodaj
                <TiPlusOutline className="ml-3" />
              </Button>
            }
            headerTitle="Dodaj markice"
            buttonTitle="Dodaj"
            onSubmit={() => stampsFormRef?.current?.handleSubmit()}
            content={
              <FormFactory
                formFields={warehouseStampsAddFields(
                  associations,
                  animals,
                  animalStampTypes,
                  getAnimalStamps,
                  getLastStampNumber,
                  query?.association_id,
                  lastAvailableStamp,
                )}
                onSubmit={onAddStamps}
                formRef={stampsFormRef}
                validationSchema={CreateStampsSchema}
              />
            }
          />
        ) : (
          <></>
        )
      }
    >
      <div className="space-y-4">
        {!isAdmin && (
          <TableFilterForm
            showFilters={true}
            filterFields={filterFields(associations)}
            onSubmit={setFilters}
            formRef={filtersFormRef}
          />
        )}
        {stamps?.length !== 0 ? (
          <StampCollapsible
            data={stamps}
            onDelete={onDeleteStamp}
            onEdit={(item) => {
              setEditStamp(item);
              getAnimalStamps(String(item?.animal_id));
              setOpenEditModal(!openEditModal);
            }}
            roles={loginData?.data?.roles}
          />
        ) : (
          <div className="flex items-center justify-center  rounded-lg border border-gray-200 p-4">
            <RxValueNone size={20} className="text-gray-600" />
            <p className="ml-1 text-xs text-gray-500">Nema dodatih markica</p>
          </div>
        )}

        <Modal
          open={openEditModal}
          setOpen={setOpenEditModal}
          headerTitle="Izmeni markice"
          buttonTitle="Izmeni"
          onSubmit={() => stampsEditFormRef?.current?.handleSubmit()}
          content={
            <FormFactory
              formFields={warehouseStampsEditFields(
                associations,
                animals,
                animalStampTypes,
                getAnimalStamps,
                getLastStampNumber,
                editStamp,
                lastAvailableStamp,
              )}
              onSubmit={onEditStamp}
              formRef={stampsEditFormRef}
              validationSchema={CreateStampsSchema}
            />
          }
        />
      </div>
    </PageLayout>
  );
};

export default WarehouseStampsList;
