import { Button } from "@/components/ui/button";
import { PopoverClose } from "@radix-ui/react-popover";
import PopoverButton from "./PopoverButton";

const ConfirmationPopover = ({
  title,
  button,
  onDelete,
}: {
  title: string;
  button: JSX.Element;
  onDelete?: () => void;
}) => {
  return (
    <PopoverButton
      button={button}
      content={
        <div className="flex flex-col space-y-4">
          <p className="text-sm text-muted-foreground">{title}</p>
          <div className="flex flex-row items-center justify-end space-x-4">
            <PopoverClose asChild>
              <Button size="sm" variant="modalDecline">
                Ne
              </Button>
            </PopoverClose>
            <PopoverClose asChild>
              <Button size="sm" variant="modalConfirm" onClick={onDelete}>
                Da
              </Button>
            </PopoverClose>
          </div>
        </div>
      }
      className="w-64 rounded-lg p-4"
    />
  );
};

export default ConfirmationPopover;
