import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-orders-fields";
import { useEffect, useState } from "react";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { IHuntingOrders } from "@/common/types/HuntingOrders";
import { useNavigate } from "react-router";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import Utils from "@/common/utils/Utils";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import useDropdownData from "@/common/hooks/useDropdownData";

const HuntingCardOrdersList = () => {
  const [data, setData] = useState<IHuntingOrders[]>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });

  const { loginData, isUser } = useAuth();

  const hasAssociation =
    !!loginData?.data?.association ||
    Utils.hasPermission(loginData?.data?.roles, [ROLES.Agency]);

  const navigate = useNavigate();

  const getHuntingCardOrders = async () => {
    const res = await HuntingCardOrdersService.all(
      Utils.formatQueryString(query),
    );
    setData(res?.data);
  };

  const onDelete = async (id?: string) => {
    await HuntingCardOrdersService.delete(id);
    getHuntingCardOrders();
  };

  useEffect(() => {
    getHuntingCardOrders();
  }, [query]);

  return (
    <PageLayout
      title="Zahtevi za lovne karte"
      showCreateButton={Utils.hasPermission(loginData?.data?.roles, [
        ROLES.User,
        ROLES.Agency,
      ])}
    >
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(hasAssociation, associations, organizations)}
        filterQueries={filterQueries}
        onEdit={(item) =>
          isUser
            ? navigate(`/hunting-card-orders/create/${item?.id}`)
            : navigate(`/hunting-card-orders/${item?.id}`)
        }
        onDelete={(id) => onDelete(id)}
        hideEdit={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
            ROLES.User,
            ROLES.Agency,
          ])
        }
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
          ])
        }
        hidePrint={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersList;
