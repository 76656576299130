import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "@/components/table/Table";
import { StampsService } from "@/common/API/StampsService";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./stamp-orders-fields";
import { IStamp } from "@/common/types/Stamps";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import useDropdownData from "@/common/hooks/useDropdownData";

const StampOrdersList = () => {
  const [data, setData] = useState<IStamp[]>([]);

  const { loginData } = useAuth();
  const navigate = useNavigate();
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });
  const hasAssociation = !!loginData?.data?.association;

  const getStampOrders = async () => {
    const res = await StampsService.all(Utils.formatQueryString(query));
    setData(res?.data);
  };

  useEffect(() => {
    getStampOrders();
  }, [query]);

  const onDeleteStampOrder = async (id?: string) => {
    await StampsService.delete(id);
    getStampOrders();
  };

  return (
    <PageLayout title="Zahtevi za markice">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(associations, organizations, hasAssociation)}
        filterQueries={filterQueries}
        onEdit={(item) => navigate(`/stamp-orders/${item?.id}`)}
        onDelete={(id) => onDeleteStampOrder(id)}
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
          ])
        }
        hidePrint={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default StampOrdersList;
