import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import Table from "@/components/table/Table";
import { tableColumns } from "./hunting-plan-fields";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { AnimalsService } from "@/common/API/AnimalsService";
import { AnimalData, AnimalStamp } from "@/common/types/Animals";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import HuntingUtils from "@/common/utils/HuntingUtils";
import PageLayout from "@/components/layout/PageLayout";
import { BsSend } from "react-icons/bs";
import { useNavigate, useParams } from "react-router";
import Toast from "@/common/Toast";

const HuntingPlanRevisionList = () => {
  const [animals, setAnimals] = useState<AnimalStamp[] | null>(null);
  const [animalInputData, setAnimalInputData] = useState<AnimalData[]>([]);
  const { loginData } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();

  const getAnimals = async () => {
    const response = await AnimalsService.all();
    setAnimals(response?.data);
  };
  const onSubmit = async (data: AnimalData[]) => {
    await HuntingGroundsService.createHuntingPlan(id, data);

    navigate("./../../");
  };

  const onAnimalInputChange = (
    animal_id: number,
    name: string,
    val: number,
    type: number,
  ) => {
    setAnimalInputData((prevData: AnimalData[]) =>
      HuntingUtils.handleAnimalPlanInput(animal_id, name, val, type, prevData),
    );
  };

  useEffect(() => {
    getAnimals();
  }, []);

  return (
    <PageLayout
      title="Revizija"
      backUrl="./../../"
      buttons={
        <div className="flex flex-row items-center space-x-2">
          {animals &&
            Utils.hasPermission(loginData?.data?.roles, [ROLES.User]) && (
              <Button type="button" onClick={() => onSubmit(animalInputData)}>
                Pošalji
                <BsSend className="ml-3" />
              </Button>
            )}
        </div>
      }
    >
      {animals && (
        <Table
          data={
            Utils.hasPermission(loginData?.data?.roles, [ROLES.User])
              ? animals
              : { data: [] }
          }
          columns={tableColumns(onAnimalInputChange)}
          hideActions={true}
          hidePagination={true}
        />
      )}
    </PageLayout>
  );
};

export default HuntingPlanRevisionList;
