import { FiEye, FiEyeOff } from "react-icons/fi";
import { FieldProps } from "formik";
import { useState } from "react";
import InputRequiredTooltip from "./InputRequiredTooltip";

const PasswordInput = ({
  field,
  form,
  placeholder,
  label,
  required,
  disabled,
  className,
  focus,
  ...props
}: FieldProps<any> & {
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  focus?: boolean;
  className?: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const error = form.errors[field.name];

  return (
    <div className={`${className} relative`}>
      <div className="flex flex-row items-center space-x-1">
        <label className="mb-2 block text-xs text-label">{label}</label>
        {required && <InputRequiredTooltip label="Polje je obavezno" />}
      </div>
      <div className="relative">
        <span
          className="absolute right-2 top-2.5 cursor-pointer "
          onClick={() => setIsVisible(!isVisible)}
        >
          {isVisible ? (
            <FiEyeOff className="text-label transition-all duration-300 hover:text-primary" />
          ) : (
            <FiEye className="text-label transition-all duration-300 hover:text-primary" />
          )}
        </span>
        <input
          type={!isVisible ? "password" : "text"}
          {...field}
          {...props}
          className={`w-full rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label focus:border-primary  disabled:pointer-events-none disabled:opacity-50 ${error && !disabled ? "border-error bg-red-50 text-red-900 placeholder-red-700 focus:border-error focus:ring-error" : ""}`}
          placeholder={placeholder}
          autoFocus={focus}
        />
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      </div>
    </div>
  );
};

export default PasswordInput;
