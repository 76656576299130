import { Field, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../common/context/AuthProvider";
import { AuthService } from "../../common/API/AuthService";
import Input from "../../components/forms/Input";
import { Button } from "@/components/ui/button";
import { ForgotPasswordSchema } from "@/common/schemas/Auth";
import { Link } from "react-router-dom";
import { ForgotPasswordValues } from "@/common/types/Auth";

const ForgotPassword = () => {
  const [isLoading, setisLoading] = useState(false);
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<ForgotPasswordValues>>(null);

  const handleSubmit = async (values: { email: string }) => {
    try {
      setisLoading(true);
      await AuthService.forgot(values);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/hunting-cards");
  }, [isLoggedIn]);

  return (
    <main className="flex flex-col items-center bg-white p-8 lg:shadow-md">
      <p className="mb-2 text-lg">Resetovanje lozinke</p>
      <Formik
        innerRef={formRef}
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          <Field
            name="email"
            type="email"
            placeholder="Unesite email"
            label="Email"
            component={Input}
            focus={true}
            className="min-w-72"
          />
          <Button type="submit" className="mt-6 w-full" isLoading={isLoading}>
            Pošalji zahtev
          </Button>
          <Link to={"/login"} className="mt-2 block text-sm text-primary">
            Vrati se na login
          </Link>
        </Form>
      </Formik>
    </main>
  );
};

export default ForgotPassword;
