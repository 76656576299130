import { Candidate, Exam } from "@/common/types/Exams";
import Input from "@/components/forms/Input";
import Text from "@/components/Text";

export const tableColumns = [
  {
    title: "Redni broj",
    dataIndex: "serial_number",
    key: "serial_number",
    render: (data: Exam, index: number) => {
      return <Text variant="table">{index + 1}</Text>;
    },
  },
  {
    title: "Prezime",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Ime oca",
    dataIndex: "middle_name",
    key: "middle_name",
  },
  {
    title: "Ime",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "JMBG/Broj pasoša",
    dataIndex: "personal_id",
    key: "personal_id",
  },
  {
    title: "Adresa",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Grupa pitanja",
    dataIndex: "question_group",
    key: "question_group",
  },
  {
    title: "Broj uverenja",
    dataIndex: "certificate_number",
    key: "certificate_number",
  },
];

export const filterFields = [
  {
    controlType: Input,
    className: "md:col-span-3 col-span-12",
    options: {
      label: "Ime",
      name: "first_name",
      placeholder: "Pretraži po imenu",
      value: "",
      focus: true,
    },
  },
  {
    controlType: Input,
    className: "md:col-span-3 col-span-12",
    options: {
      label: "Prezime",
      name: "last_name",
      placeholder: "Pretraži po prezimenu",
      value: "",
    },
  },
  {
    controlType: Input,
    className: "md:col-span-3 col-span-12",
    options: {
      label: "JMBG/Broj pasoša",
      name: "personal_id",
      placeholder: "Pretraži po JMBG/Broju pasoša",
      value: "",
    },
  },
];

export const filterQueries = {
  first_name: "",
  last_name: "",
  personal_id: "",
};

export const createCandidateFields = (data?: Candidate) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Prezime",
        name: "last_name",
        placeholder: "Unesite prezime",
        value: data?.last_name ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Ime oca",
        name: "middle_name",
        placeholder: "Unesite ime oca",
        value: data?.middle_name ?? "",
        required: false,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Ime",
        name: "first_name",
        placeholder: "Unesite ime",
        value: data?.first_name ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "JMBG/Broj pasoša",
        name: "personal_id",
        placeholder: "Unesite JMBG/Broj pasoša",
        value: data?.personal_id ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Unesite adresu",
        value: data?.address ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Grupa pitanja",
        name: "question_group",
        placeholder: "Unesite grupu pitanja",
        value: data?.question_group ?? "",
        required: true,
      },
    },
  ];
};
