import Utils from "../utils/Utils";

const ROLES = {
  Superadmin: 1,
  Admin: 2,
  User: 3,
  Agency: 4,
  Inspector: 5,
  Ranger: 6,
  Moderator: 7,
  Examiner: 8,
  all: () => {
    return Utils.getObjectKeyValue(ROLES)[1];
  },
};

export default ROLES;
