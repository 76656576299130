import { AnimalInputType } from "@/common/enums/animals";
import { DropdownData } from "@/common/types/Form";
import Text from "@/components/Text";
import AnimalInput from "@/components/forms/AnimalInput";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";

export const tableColumns = (
  onAnimalInputChange: (
    animal_id: number,
    name: string,
    val: number,
    type: number,
  ) => void,
) => {
  return [
    {
      title: "Vrsta divljači",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Matični fond",
      dataIndex: "native",
      key: "native",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="native"
          placeholder="Matični broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Optimalni fond",
      dataIndex: "optimal",
      key: "optimal",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="optimal"
          placeholder="Optimalni broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Minimalni fond",
      dataIndex: "minimal",
      key: "minimal",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="minimal"
          placeholder="Minimalni broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Mužjak",
      dataIndex: "male",
      key: "male",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="male"
            placeholder="Mužjak"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Ženka",
      dataIndex: "female",
      key: "female",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="female"
            placeholder="Ženka"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Podmladak",
      dataIndex: "offspring",
      key: "offspring",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="offspring"
            placeholder="Podmladak"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Ukupno",
      dataIndex: "total",
      key: "total",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <div>
            <AnimalInput
              name="total"
              placeholder="Ukupno"
              disabled={input_type === AnimalInputType.Partial}
              onChange={(name: string, val: number) =>
                onAnimalInputChange(id, name, val, input_type)
              }
            />
          </div>
        );
      },
    },
  ];
};

export const huntingPlanTableColumns = [
  {
    title: "Vrsta divljači",
    dataIndex: ["animal", "name"],
    key: ["animal", "name"],
  },
  {
    title: "Matični fond",
    dataIndex: "native",
    key: "native",
  },
  {
    title: "Optimalni fond",
    dataIndex: "optimal",
    key: "optimal",
  },
  {
    title: "Minimalni fond",
    dataIndex: "minimal",
    key: "minimal",
  },
  {
    title: "Mužjak",
    dataIndex: "male",
    key: "male",
    render: ({ male }: { male: number }) => (
      <Text variant="table">{male ? male : "-"}</Text>
    ),
  },
  {
    title: "Ženka",
    dataIndex: "female",
    key: "female",
    render: ({ female }: { female: number }) => (
      <Text variant="table">{female ? female : "-"}</Text>
    ),
  },
  {
    title: "Podmladak",
    dataIndex: "offspring",
    key: "offspring",
    render: ({ offspring }: { offspring: number }) => (
      <Text variant="table">{offspring ? offspring : "-"}</Text>
    ),
  },
  {
    title: "Ukupno",
    dataIndex: "total",
    key: "total",
    render: ({ total }: { total: number }) => (
      <Text variant="table">{total ? total : "-"}</Text>
    ),
  },
];

export const filterFields = (
  huntingGrounds?: DropdownData[],
  revisions?: { name: string; id: number }[],
) => {
  return [
    {
      controlType: DropdownSearch,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovište",
        name: "id",
        placeholder: "Izaberite lovište",
        value: "",
        options: huntingGrounds,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Revizija",
        name: "revision_number",
        placeholder: "Izaberite reviziju",
        value: "",
        options: revisions,
        hide: revisions?.length! <= 1,
      },
    },
  ];
};

export const filterQueries = {
  id: "",
  revision_number: "1",
  season: "",
};
