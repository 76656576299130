import { useAuth } from "@/common/context/AuthProvider";
import { useNavigate } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { examsCreateFields } from "./exams-fields";
import { useEffect, useRef, useState } from "react";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { ExamCreateData } from "@/common/types/Exams";
import { ExamsService } from "@/common/API/ExamsService";
import FormUtils from "@/common/utils/FormUtils";
import { CreateExamSchema } from "@/common/schemas/Exams";
import useDropdownData from "@/common/hooks/useDropdownData";
import { TiPlusOutline } from "react-icons/ti";
import { FormikProps } from "formik";
import ROLES from "@/common/enums/roles";

const ExamsAdd = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getOrganizations } = useDropdownData({
    hasAgency: true,
  });

  const { loginData } = useAuth();
  const navigate = useNavigate();
  const isExaminer =
    loginData?.data?.roles?.filter((role) => role?.id === ROLES.Examiner)
      ?.length !== 0;

  const formRef = useRef<FormikProps<ExamCreateData>>(null);

  useEffect(() => {
    getOrganizations();
    formRef?.current?.setFieldValue("creator", loginData?.data?.name);
  }, []);

  const onSubmit = async (values: ExamCreateData) => {
    try {
      setIsLoading(true);

      let examData: ExamCreateData = {
        ...values,
        members_of_commission: [values?.examiner1, values?.examiner2],
      };

      const formData = new FormData();
      FormUtils.appendDataToFormData(examData, formData);

      await ExamsService.create(formData);
      navigate(`./../`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      backUrl="./../"
      title="Kreiranje ispita"
      buttons={
        <Button
          type="button"
          isLoading={isLoading}
          onClick={() => formRef?.current?.handleSubmit()}
          hide={!isExaminer}
        >
          Kreiraj
          <TiPlusOutline className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={examsCreateFields()}
        onSubmit={onSubmit}
        formRef={formRef}
        validationSchema={CreateExamSchema}
      />
    </PageLayout>
  );
};

export default ExamsAdd;
