import HeaderAvatar from "./HeaderAvatar";

const Header = () => {
  return (
    <div className="flex h-16 items-center justify-between border-b border-gray-100 bg-white px-4">
      <div></div>
      <HeaderAvatar />
    </div>
  );
};

export default Header;
