import { FieldInputProps, FieldProps, FormikProps } from "formik";
import InputRequiredTooltip from "./InputRequiredTooltip";

type InputProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  focus?: boolean;
  inputtype?: string;
  unit?: string;
};

type InputFormProps = FieldProps<any> & InputProps;

const Input = ({
  field,
  form,
  placeholder,
  label,
  disabled,
  required,
  className,
  focus,
  inputtype = "text",
  unit,
  ...props
}: InputFormProps | InputProps) => {
  const error = form?.errors?.[field?.name!];

  return (
    <div className={`${className} relative`}>
      {label && (
        <div className="flex flex-row items-center space-x-1">
          <label className="mb-2 block text-xs text-label">{label}</label>
          {required && <InputRequiredTooltip label="Polje je obavezno" />}
        </div>
      )}

      <input
        {...field}
        {...props}
        maxLength={inputtype === "number" ? 7 : 60}
        disabled={disabled}
        className={`w-full rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label focus:border-primary disabled:pointer-events-none disabled:opacity-50 ${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""}`}
        placeholder={placeholder}
        autoFocus={focus}
      />
      {unit && (
        <div
          className={`absolute -right-1 bottom-0 rounded-e-lg border border-gray-300 bg-gray-200 p-2.5 text-xs text-label disabled:opacity-50 ${error && !disabled ? "border-red-500" : ""}`}
        >
          {unit}
        </div>
      )}
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default Input;
