import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "./ui/button";
import { FiSave } from "react-icons/fi";
import { Dispatch, SetStateAction } from "react";

function Modal({
  triggerButton,
  headerTitle,
  buttonTitle,
  onSubmit,
  content,
  open,
  setOpen,
  hideSubmit,
}: {
  triggerButton?: JSX.Element;
  headerTitle: string;
  buttonTitle: string;
  onSubmit: () => void;
  content: JSX.Element;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hideSubmit?: boolean;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {triggerButton ? (
        <DialogTrigger asChild>{triggerButton}</DialogTrigger>
      ) : null}
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <h1 className="text-md">{headerTitle}</h1>
          <Button
            type="button"
            className="self-end"
            onClick={onSubmit}
            hide={hideSubmit}
          >
            {buttonTitle}
            <FiSave className="ml-3" />
          </Button>
        </DialogHeader>
        {content}
      </DialogContent>
    </Dialog>
  );
}

export default Modal;
