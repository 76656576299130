import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "@/components/table/Table";
import { filterFields, filterQueries, tableColumns } from "./users-fields";
import { UsersService } from "@/common/API/UsersService";
import { IUser } from "@/common/types/User";
import PageLayout from "@/components/layout/PageLayout";
import Utils from "@/common/utils/Utils";
import { useQuery } from "@/common/hooks/useQuery";
import { useAuth } from "@/common/context/AuthProvider";
import useDropdownData from "@/common/hooks/useDropdownData";
import ROLES from "@/common/enums/roles";

const UsersList = () => {
  const [data, setData] = useState<IUser[]>([]);
  const { loginData } = useAuth();
  const navigate = useNavigate();
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);
  const hasAssociation = !!loginData?.data?.association;

  const getUsers = async () => {
    const res = await UsersService.all(Utils.formatQueryString(query));

    setData(res?.data);
  };

  const onDelete = async (id?: string) => {
    await UsersService.delete(id);
    getUsers();
  };

  useEffect(() => {
    getUsers();
  }, [query]);

  return (
    <PageLayout title="Korisnički nalozi" showCreateButton={isAdmin}>
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(associations, organizations, hasAssociation)}
        filterQueries={filterQueries}
        onEdit={(item) => navigate(`/users/${item?.id}`)}
        onDelete={(id) => onDelete(id)}
        hidePrint={true}
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Moderator,
          ])
        }
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default UsersList;
