import { AiOutlineEdit } from "react-icons/ai";
import { IoPrintOutline } from "react-icons/io5";
import TableActionButton from "./TableActionButton";
import ConfirmationPopover from "../ConfirmationPopover";
import { CgTrash } from "react-icons/cg";

type TableActionButtonsProps = {
  actionButtons?: (data: any) => JSX.Element;
  hideActions?: boolean;
  hideEdit?: boolean;
  hideDelete?: boolean;
  hidePrint?: boolean;
  onEdit?: (data: any) => void;
  onDelete?: (id: string) => void;
  onPrint?: (data: any) => void;
};

const tableActionButtons = ({
  actionButtons,
  hideActions,
  hideEdit,
  hideDelete,
  hidePrint,
  onEdit,
  onDelete,
  onPrint,
}: TableActionButtonsProps) => {
  if (hideActions) {
    return [];
  }

  return [
    {
      title: "Akcije",
      dataIndex: "actions",
      key: "actions",
      render: (data: any) => (
        <div className="flex flex-row items-center space-x-2 self-center">
          {!hideEdit && (
            <TableActionButton
              icon={<AiOutlineEdit size={16} />}
              tooltipText="Detalji"
              onClick={() => onEdit?.(data)}
            />
          )}
          {!hideDelete && (
            <ConfirmationPopover
              title="Da li ste sigurni? Stavka će biti obrisana."
              onDelete={() => onDelete?.(data?.id)}
              button={
                <div>
                  <CgTrash
                    size={15}
                    className="cursor-pointer self-center text-gray-400 transition-all duration-300 hover:text-red-500"
                  />
                </div>
              }
            />
          )}
          {!hidePrint && (
            <TableActionButton
              icon={
                <IoPrintOutline size={16} onClick={() => onPrint?.(data)} />
              }
              tooltipText="Štampaj"
            />
          )}
          {actionButtons && actionButtons?.(data)}
        </div>
      ),
    },
  ];
};

export default tableActionButtons;
