import config from "../config/config";
import { axios } from "../config/axios";

export const AssociationsService = {
  async all_dropdown() {
    const response = await axios.get(`${config.baseUrl}/associations/all`);

    return response;
  },
};
