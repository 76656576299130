const Text = ({
  variant,
  fontColor = "dark",
  className,
  children,
}: {
  variant: string;
  fontColor?: "light" | "dark";
  className?: string;
  children: any;
}) => {
  let textStyle = `${fontColor === "light" ? "text-text" : ""}`;

  switch (variant) {
    case `h1`:
      textStyle = `${textStyle} text-4xl font-bold text-font`;
      break;
    case `h2`:
      textStyle = `${textStyle} text-3xl font-bold text-font`;
      break;
    case `h3`:
      textStyle = `${textStyle} text-2xl font-bold text-font`;
      break;
    case `h4`:
      textStyle = `${textStyle} text-xl font-bold text-font`;
      break;
    case `h5`:
      textStyle = `${textStyle} text-lg font-bold text-font`;
      break;
    case `h6`:
      textStyle = `${textStyle} text-base font-bold text-font`;
      break;
    case `subtitle`:
      textStyle = `${textStyle} text-md text-font`;
      break;
    case `body`:
      textStyle = `${textStyle} text-base text-font`;
      break;
    case `caption`:
      textStyle = `${textStyle} text-sm text-font`;
      break;
    case `table`:
      textStyle = `${textStyle} font-normal leading-none`;
      break;
    case "label":
      textStyle = `${textStyle} text-sm text-label`;
      break;
    default:
      textStyle = `${textStyle} text-base text-font`;
  }

  return <div className={`${textStyle} ${className}`}>{children}</div>;
};

export default Text;
