import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useQuery = ({
  hasPagination,
  filterQueries,
}: {
  hasPagination: boolean;
  filterQueries?: Record<string, string>;
}) => {
  const [searchParams] = useSearchParams();

  const paginationConfig: {
    page: string;
    per_page: string;
    sort: string;
    order: string;
  } = {
    page: "1",
    per_page: "20",
    sort: "",
    order: "",
  };

  const queryConfig: Record<string, string> = {
    ...(hasPagination ? paginationConfig : {}),
    ...filterQueries,
  };

  const initialState = Object.keys(queryConfig).reduce(
    (acc: Record<string, string>, key) => {
      acc[key] = searchParams.get(key) || queryConfig[key];
      return acc;
    },
    {},
  );
  const [query, setQuery] = useState<Record<string, string>>(initialState);

  const decreasePage = () => {
    setQuery((prevState: Record<string, string>) => {
      const newPage =
        Number(prevState.page) <= 1
          ? prevState.page
          : String(Number(prevState.page) - 1);

      return {
        ...prevState,
        page: newPage,
      };
    });
  };

  const increasePage = (totalPages?: number) => {
    setQuery((prevState: Record<string, string>) => {
      const newPage =
        Number(prevState.page) >= Number(totalPages)
          ? prevState.page
          : String(Number(prevState.page) + 1);

      return {
        ...prevState,
        page: newPage,
      };
    });
  };

  const setFirstPage = () => {
    setQuery((prevState: Record<string, string>) => ({
      ...prevState,
      page: "1",
    }));
  };

  const setLastPage = (totalPages?: number) => {
    setQuery((prevState: Record<string, string>) => ({
      ...prevState,
      page: String(totalPages),
    }));
  };

  const setPerPage = (value: string) => {
    setQuery((prevState: Record<string, string>) => ({
      ...prevState,
      page: "1",
      per_page: String(value),
    }));
  };

  const setFilters = (values: any) => {
    setQuery((prevState: Record<string, string>) => ({
      ...prevState,
      ...values,
      ...(hasPagination ? { page: "1" } : {}),
    }));
  };

  const resetQuery = (queryKey: string) => {
    setQuery((prevState: Record<string, string>) => ({
      ...prevState,
      [queryKey]: "",
    }));
  };

  useEffect(() => {
    const params = new URLSearchParams();

    for (let queryKey in query) {
      params.set(queryKey, query[queryKey]);
    }
    // Get the current search params from the URL
    const currentSearchParams = new URLSearchParams(searchParams.toString());

    // Check if the query parameters have actually changed
    for (let queryKey in query) {
      if (currentSearchParams.get(queryKey) !== query[queryKey]) {
        window.history.pushState({}, "", "?" + params.toString());
        break;
      }
    }
  }, [query, searchParams]);

  return {
    query,
    setQuery,
    setFilters,
    resetQuery,
    paginationMethods: {
      decreasePage,
      increasePage,
      setFirstPage,
      setLastPage,
      setPerPage,
    },
  };
};
