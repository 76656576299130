export const HuntingCardOrderStatus = {
  Open: 1,
  Sent: 2,
  Rejected: 3,
  Accepted: 4,
};

export const HuntingExamsStatus = {
  Open: 1,
  Sent: 2,
  Rejected: 3,
  Accepted: 4,
};

export const HuntingCardType = {
  Tourist: 1,
  Member: 2,
};

export const HunterGender = {
  MALE: 1,
  FEMALE: 2,
};
