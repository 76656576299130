import { SidebarChild, SidebarItemType } from "@/common/types/Sidebar";
import SidebarChildItem from "./SidebarChildItem";

const SidebarItem = ({ label, route, children }: SidebarItemType) => {
  return (
    <div>
      {route ? (
        <SidebarChildItem route={route} label={label} />
      ) : (
        <div className="flex flex-col">
          <p className="px-6 py-2 text-sm uppercase text-sidebarTitleText">
            {label}
          </p>
          {children?.map((child: SidebarChild, index: number) => (
            <SidebarChildItem
              route={child?.route}
              label={child?.label}
              icon={child?.icon}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SidebarItem;
