import ROLES from "@/common/enums/roles";
import Checkbox from "@/components/forms/Checkbox";
import Input from "@/components/forms/Input";
import PasswordInput from "@/components/forms/PasswordInput";

export const profileFields = (role?: number) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ime i prezime",
        name: "name",
        placeholder: "Ime korisnika",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Email",
        name: "email",
        placeholder: "Unesite email",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lozinka",
        name: "password",
        placeholder: "Unesite lozinku",
        value: "",
        hide: true,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ponovite lozinku",
        name: "password_confirmation",
        placeholder: "Unesite lozinku",
        value: "",
        hide: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "JMBG/Broj pasoša",
        name: "unique_identifier",
        placeholder: "Unesite JMBG/Broj pasoša",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Telefon",
        name: "phone",
        placeholder: "Unesite telefon",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Unesite adresu",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Mesto",
        name: "residence",
        placeholder: "Unesite mesto",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Poštanski broj",
        name: "postal_code",
        placeholder: "Unesite poštanski broj",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Rola",
        name: "role_id",
        placeholder: "Izaberite rolu",
        value: "",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Regionalni/Pokrajinski savez",
        name: "association_id",
        placeholder: "Izaberite savez",
        value: "",
        disabled: true,
        hide: [
          ROLES.Superadmin,
          ROLES.Agency,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberite korisnika lovišta ili agenciju",
        value: "",
        disabled: true,
        hide: [
          ROLES.Superadmin,
          ROLES.Admin,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: Checkbox,
      className: "col-span-12 mb-3",
      options: {
        label: "Da li je korisnik predsednik komisije?",
        name: "is_examiner",
        placeholder: "Predsednik komisije",
        value: false,
        disabled: true,
      },
    },
  ];
};
