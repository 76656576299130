import { useEffect, useState } from "react";
import { DropdownData } from "../types/Form";
import { AssociationsService } from "../API/AssociationsService";
import { OrganizationsService } from "../API/OrganizationsService";
import { useAuth } from "../context/AuthProvider";
import Utils from "../utils/Utils";
import ROLES from "../enums/roles";

const useDropdownData = ({
  hasAgency,
  query,
}: {
  hasAgency: boolean;
  query?: Record<string, string>;
}) => {
  const [associations, setAssociations] = useState<DropdownData[]>([]);
  const [organizations, setOrganizations] = useState<DropdownData[]>([]);
  const [organizationsWithWarehouse, setOrganizationsWithWarehouse] = useState<
    DropdownData[]
  >([]);

  const { loginData } = useAuth();
  const hasAssociation = !!loginData?.data?.association;
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Moderator,
  ]);

  const getAssociations = async () => {
    const response = await AssociationsService.all_dropdown();

    if (hasAgency) {
      setAssociations(response?.data?.data);
    } else {
      const filteredData = response?.data?.data.filter(
        (el: DropdownData) => el.id !== -1,
      );

      setAssociations(filteredData);
    }
  };

  const getOrganizations = async (associationId?: string | number) => {
    const response = await OrganizationsService.all_dropdown(associationId);

    setOrganizations(response?.data?.data);
  };

  const getOrganizationsWithWarehouse = async () => {
    const response = await OrganizationsService.all_with_warehouse();

    setOrganizationsWithWarehouse(response?.data?.data);
  };

  useEffect(() => {
    getAssociations();
  }, []);

  useEffect(() => {
    getOrganizations(
      hasAssociation ? loginData?.data?.association?.id : query?.association_id,
    );

    isAdmin && getOrganizationsWithWarehouse();
  }, [query]);

  return {
    associations,
    organizations,
    organizationsWithWarehouse,
    getAssociations,
    getOrganizations,
    getOrganizationsWithWarehouse,
  };
};

export default useDropdownData;
