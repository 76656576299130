import TooltipWrapper from "../TooltipWrapper";

const TableActionButton = ({
  icon,
  tooltipText,
  onClick,
}: {
  icon: JSX.Element;
  tooltipText: string;
  onClick?: () => void;
}) => {
  return (
    <TooltipWrapper
      button={
        <div
          onClick={onClick}
          className="cursor-pointer p-1 text-gray-400 transition-all duration-300 hover:text-primary"
        >
          {icon}
        </div>
      }
      text={tooltipText}
    />
  );
};

export default TableActionButton;
