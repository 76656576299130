import config from "../config/config";
import { axios } from "../config/axios";

export const StampsService = {
  async all(query: string) {
    const response = await axios.get(`${config.baseUrl}/stamp-orders?${query}`);

    return response;
  },

  async one(id?: string) {
    const response = await axios.get(`${config.baseUrl}/stamp-orders/${id}`);

    return response;
  },

  async accept(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/stamp-orders/${id}/accept`,
    );

    return response;
  },

  async delete(id?: string) {
    const response = await axios.delete(`${config.baseUrl}/stamp-orders/${id}`);

    return response;
  },

  async getPdf(id?: string) {
    const response = await axios.get(
      `${config.baseUrl}/stamp-orders/${id}/pdf`,
    );

    return response;
  },
};
