import { StampsService } from "@/common/API/StampsService";
import { IHuntingStamp } from "@/common/types/HuntingPlans";
import StampCardItem from "@/components/StampCardItem";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button } from "@/components/ui/button";
import { StampOrderStatus } from "@/common/enums/stamps";
import PageLayout from "@/components/layout/PageLayout";
import { IoCheckboxOutline } from "react-icons/io5";
import { useAuth } from "@/common/context/AuthProvider";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";
import { GrDocumentDownload } from "react-icons/gr";

const StampOrdersEdit = () => {
  const [data, setData] = useState<IHuntingStamp | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { loginData } = useAuth();

  const getStampOrder = async () => {
    const response = await StampsService.one(id);

    setData(response?.data?.data);
  };

  useEffect(() => {
    getStampOrder();
  }, []);

  const onAccept = async () => {
    await StampsService.accept(id);
    navigate("./../");
  };

  const onDownloadPdf = async () => {
    setIsLoading(true);
    const response = await StampsService.getPdf(id);

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  return (
    <PageLayout
      backUrl="./../"
      title={`Godišnji plan - ${data?.hunting_ground?.name}`}
      buttons={
        <>
          <Button
            type="button"
            variant="secondary"
            onClick={onDownloadPdf}
            isLoading={isLoading}
            hide={data?.status === StampOrderStatus.Unanswered}
          >
            Preuzmi dodeljene markice
            <GrDocumentDownload className="ml-3 text-text" />
          </Button>
          {data?.status === StampOrderStatus.Unanswered && (
            <Button
              type="button"
              onClick={onAccept}
              hide={
                !Utils.hasPermission(loginData?.data?.roles, [
                  ROLES.Superadmin,
                  ROLES.Admin,
                  ROLES.Moderator,
                ])
              }
            >
              Odobri
              <IoCheckboxOutline className="ml-3" />
            </Button>
          )}
        </>
      }
    >
      <div className="row-start-2 grid grid-cols-12 gap-4">
        {data?.hunting_ground?.animal_hunting_plans?.map(
          (animalPlan, index) => (
            <StampCardItem
              key={index}
              name={animalPlan?.animal?.name}
              male={animalPlan?.male}
              female={animalPlan?.female}
              offspring={animalPlan?.offspring}
              total={animalPlan?.total}
              input_type={animalPlan?.animal?.input_type}
            />
          ),
        )}
      </div>
    </PageLayout>
  );
};

export default StampOrdersEdit;
