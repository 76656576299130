import CONSTANTS from "@/common/config/constants";
import { DropdownData } from "@/common/types/Form";
import { IAssociation } from "@/common/types/HuntingOrders";
import { IOrganization } from "@/common/types/Organizations";
import Dropdown from "@/components/forms/Dropdown";
import Input from "@/components/forms/Input";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import { Dispatch, SetStateAction } from "react";

export const tableColumns = [
  {
    title: "Naziv",
    dataIndex: "name",
    key: "name",
    sortOptions: {
      sortDirection: "",
      sortParam: "name",
    },
  },
  {
    title: "Mesto",
    dataIndex: "residence",
    key: "residence",
    sortOptions: {
      sortDirection: "",
      sortParam: "residence",
    },
  },
  {
    title: "Savez",
    dataIndex: ["association", "name"],
    key: ["association", "name"],
    render: ({ association }: { association: IAssociation }) => {
      const a = CONSTANTS.huntingAssociations.find(
        (a) => a?.id === association?.id,
      );

      return association ? (
        <div className="flex flex-row items-center space-x-1">
          <Tag title={a?.name} className={a?.className} />
        </div>
      ) : (
        <Text variant="table">-</Text>
      );
    },
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone",
  },
];

export const filterFields = (
  associations?: DropdownData[],
  hasAssociation?: boolean,
) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Naziv",
        name: "name",
        placeholder: "Pretraži po nazivu",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
        hide: hasAssociation,
      },
    },
  ];
};

export const filterQueries = {
  name: "",
  association_id: "",
};

export const organizationsCreateFields = (
  formType: string,
  setFormType: Dispatch<SetStateAction<string>>,
  assocations?: DropdownData[],
) => {
  return [
    {
      render: (index: number) => (
        <Dropdown
          key={index}
          className="col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-1"
          label="Da li kreirate korisnika lovišta ili agenciju?"
          options={[
            { name: "Korisnik lovišta", id: 1 },
            { name: "Agencija", id: 2 },
          ]}
          callback={(value: string) => setFormType(value)}
          value={formType}
        />
      ),
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-2",
      options: {
        label: "Naziv",
        name: "name",
        placeholder: "Naziv",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-2",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Unesite adresu",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-2",
      options: {
        label: "Poštanski broj",
        name: "postal_code",
        placeholder: "Unesite poštanski broj",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-2",
      options: {
        label: "Mesto",
        name: "residence",
        placeholder: "Unesite mesto",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-3",
      options: {
        label: "PIB",
        name: "vat_number",
        placeholder: "Unesite PIB",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-3",
      options: {
        label: "Matični broj",
        name: "registration_number",
        placeholder: "Unesite matični broj",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-3",
      options: {
        label: "Žiro račun 1",
        name: "bank_account",
        placeholder: "Unesite žiro račun 1",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-3",
      options: {
        label: "Žiro račun 2",
        name: "bank_account_2",
        placeholder: "Unesite žiro račun 2",
        value: "",
        required: false,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-4",
      options: {
        label: "Email",
        name: "email",
        placeholder: "Unesite email",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-4",
      options: {
        label: "Telefon",
        name: "phone",
        placeholder: "Unesite telefon",
        value: "",
        required: true,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3 lg:row-start-4",
      options: {
        label: "Regionalni/Pokrajinski savez",
        name: "association_id",
        placeholder: "Izaberite savez",
        value: "",
        options: assocations,
        hide: !(Number(formType) === 1),
        required: true,
      },
    },
  ];
};
