import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  huntingPlanTableColumns,
  tableColumns,
} from "./hunting-plan-fields";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { AnimalsService } from "@/common/API/AnimalsService";
import {
  AnimalData,
  AnimalHuntingPlanResponse,
  AnimalStamp,
} from "@/common/types/Animals";
import { DropdownData } from "@/common/types/Form";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import HuntingUtils from "@/common/utils/HuntingUtils";
import { GrDocumentDownload } from "react-icons/gr";
import PageLayout from "@/components/layout/PageLayout";
import { TiPlusOutline } from "react-icons/ti";
import { BsSend } from "react-icons/bs";
import { useQuery } from "@/common/hooks/useQuery";
import { useNavigate } from "react-router";

const HuntingPlanList = () => {
  const [animals, setAnimals] = useState<AnimalStamp[] | null>(null);
  const [huntingPlan, setHuntingPlan] =
    useState<AnimalHuntingPlanResponse | null>(null);
  const [huntingGrounds, setHuntingGrouds] = useState<DropdownData[]>([]);
  const [animalInputData, setAnimalInputData] = useState<AnimalData[]>([]);
  const [revisions, setRevisions] = useState<
    { name: string; id: number }[] | undefined
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginData } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const navigate = useNavigate();

  const getHuntingGrounds = async () => {
    const response = await HuntingGroundsService.all_dropdown();

    setHuntingGrouds(response?.data?.data);
  };

  const getHuntingPlan = async () => {
    const response = await HuntingGroundsService.getHuntingPlan({
      id: query?.id,
      revision_number: query?.revision_number,
      season: query?.season,
    });

    setHuntingPlan(response?.data);
    setAnimals(null);
  };

  const getAnimals = async () => {
    const response = await AnimalsService.all();
    setAnimals(response?.data);
  };

  const getRevisions = async () => {
    const response = await HuntingGroundsService.getRevisions(query?.id);

    const revisionData = HuntingUtils.formatRevisionData(response?.data?.data);

    setRevisions(revisionData);
  };

  const onSubmit = async (data: AnimalData[]) => {
    await HuntingGroundsService.createHuntingPlan(query?.id, data);

    getHuntingPlan();
    setAnimals(null);
    setAnimalInputData([]);
  };

  const onDownloadDocument = async () => {
    setIsLoading(true);
    const huntingPlanRevisionNumber =
      huntingPlan?.data?.animal_hunting_plans?.[0]?.revision_number;

    const response = await HuntingGroundsService.getCompensationForm(
      query?.id,
      huntingPlanRevisionNumber,
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const onAnimalInputChange = (
    animal_id: number,
    name: string,
    val: number,
    type: number,
  ) => {
    setAnimalInputData((prevData: AnimalData[]) =>
      HuntingUtils.handleAnimalPlanInput(animal_id, name, val, type, prevData),
    );
  };

  useEffect(() => {
    getHuntingGrounds();
  }, []);

  useEffect(() => {
    if (query?.id !== "") {
      getHuntingPlan();
      getRevisions();
    }
  }, [query]);

  useEffect(() => {
    if (huntingPlan?.data?.animal_hunting_plans?.length === 0) {
      getAnimals();
    }
  }, [huntingPlan]);

  return (
    <PageLayout
      title="Godišnji plan"
      buttons={
        <div className="flex flex-row items-center space-x-2">
          {animals && (
            <Button
              type="button"
              onClick={() => onSubmit(animalInputData)}
              hide={!Utils.hasPermission(loginData?.data?.roles, [ROLES.User])}
            >
              Pošalji
              <BsSend className="ml-3" />
            </Button>
          )}

          {huntingPlan?.data?.animal_hunting_plans && !animals && (
            <Button
              type="button"
              onClick={() => navigate(`/hunting-plan/revision/${query?.id}`)}
              hide={!Utils.hasPermission(loginData?.data?.roles, [ROLES.User])}
            >
              Kreiraj reviziju
              <TiPlusOutline className="ml-3" />
            </Button>
          )}

          {huntingPlan?.data?.animal_hunting_plans && !animals && (
            <Button
              type="button"
              variant="secondary"
              onClick={onDownloadDocument}
              isLoading={isLoading}
              hide={
                !Utils.hasPermission(loginData?.data?.roles, [
                  ROLES.Superadmin,
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Moderator,
                ])
              }
            >
              Dokument naknade
              <GrDocumentDownload className="ml-3 text-text" />
            </Button>
          )}
        </div>
      }
    >
      {/* Render table with hunting plan values if it exists, otherwise render animal inputs (only for user) */}
      {huntingPlan?.data?.animal_hunting_plans?.length !== 0 ? (
        <Table
          data={{ data: huntingPlan?.data?.animal_hunting_plans }}
          columns={huntingPlanTableColumns}
          filterFields={filterFields(huntingGrounds, revisions)}
          filterQueries={filterQueries}
          hideActions={true}
          hidePagination={true}
          query={query}
          setQuery={setQuery}
          setFilters={setFilters}
        />
      ) : (
        <Table
          data={
            Utils.hasPermission(loginData?.data?.roles, [ROLES.User])
              ? animals
              : { data: [] }
          }
          columns={tableColumns(onAnimalInputChange)}
          filterFields={filterFields(huntingGrounds, revisions)}
          filterQueries={filterQueries}
          hideActions={true}
          hidePagination={true}
          query={query}
          setQuery={setQuery}
          setFilters={setFilters}
        />
      )}
    </PageLayout>
  );
};

export default HuntingPlanList;
