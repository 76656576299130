import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./hunting-cards-fields";
import { HuntingCardService } from "@/common/API/HuntingCardService";
import {
  HuntingCardsResponse,
  IHuntingCard,
} from "@/common/types/HuntingCards";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";

const HuntingCardsList = () => {
  const [data, setData] = useState<HuntingCardsResponse | []>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { organizations } = useDropdownData({ hasAgency: true, query });
  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Moderator,
  ]);

  const getHuntingCards = async () => {
    const response = await HuntingCardService.all(
      Utils.formatQueryString(query),
    );

    const data = {
      data: response?.data?.data?.hunting_cards,
      pagination: response?.data?.pagination,
    };

    setData(data);
  };

  const onPrintHuntingCard = async (huntingCard: IHuntingCard) => {
    const response = await HuntingCardOrdersService.getHunterPdf(
      String(huntingCard?.hunting_card_order?.id),
      String(huntingCard?.hunter?.id),
    );

    Utils.openDocument(response?.data?.data);
  };

  useEffect(() => {
    getHuntingCards();
  }, [query]);

  return (
    <PageLayout title="Lovne karte">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(organizations)}
        filterQueries={filterQueries}
        hideActions={false}
        hideDelete={true}
        hideEdit={true}
        hidePrint={!isAdmin}
        onPrint={(huntingCard) => onPrintHuntingCard(huntingCard)}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default HuntingCardsList;
