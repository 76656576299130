import { useState } from "react";

export const useSelectedRows = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const onRowSelect = (id: number) => {
    setSelectedRows((prevState) => {
      let updatedRows;
      if (prevState?.includes(id)) {
        updatedRows = prevState?.filter((el) => el !== id);
      } else {
        updatedRows = [...prevState, id];
      }

      return updatedRows;
    });
  };

  const onRowSelectAll = () => {
    setSelectedRows([]);
    setSelectAll((prevState) => !prevState);
  };

  return {
    selectedRows,
    selectAll,
    onRowSelect,
    onRowSelectAll,
  };
};

export default useSelectedRows;
