import { WarehouseStamp, WarehouseStampData } from "@/common/types/Warehouse";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import Table from "./table/Table";
import { tableColumns } from "@/pages/cms/warehouse/stamps/warehouse-stamps-fields";
import { Role } from "@/common/types/User";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";

const StampCollapsible = ({
  data,
  onDelete,
  onEdit,
  roles,
}: {
  data: WarehouseStampData[];
  onDelete: (id: string) => void;
  onEdit: (data: WarehouseStamp) => void;
  roles?: Role[];
}) => {
  return (
    <Accordion type="multiple" className="space-y-4">
      {data?.map((element, index) => (
        <AccordionItem value={element?.animal?.name} key={index}>
          <AccordionTrigger>{element?.animal?.name}</AccordionTrigger>
          <AccordionContent className="p-2">
            <Table
              data={{
                data: element?.warehouse_stamps.map((stamp) => {
                  return {
                    ...stamp,
                    short_name: element?.animal?.short_name,
                    animal_id: element?.animal?.id,
                  };
                }),
              }}
              columns={tableColumns({
                hideActions: !Utils.hasPermission(roles, [
                  ROLES.Superadmin,
                  ROLES.Moderator,
                ]),
                onEdit,
                onDelete,
              })}
              hideActions={true}
            />
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default StampCollapsible;
