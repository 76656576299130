import Loader from "./Loader";
import logo from "../../common/static/lss-logo.png";

const ScreenLoader = () => {
  return (
    <main className="grid h-screen w-screen grid-rows-10 bg-white">
      <div className="row-span-8 flex flex-col items-center justify-center">
        <img src={logo} className="mb-2 h-24 w-24" />
        <div className="row-span-2 flex items-center justify-center">
          <Loader />
        </div>
      </div>
    </main>
  );
};

export default ScreenLoader;
