import React, { useState, useEffect } from "react";
import Text from "./Text";
import { BsInfoCircle } from "react-icons/bs";
import TooltipWrapper from "./TooltipWrapper";

type ProgressInputType = {
  name: string;
  value?: number | null;
  total?: number;
  percent?: number;
  percentValue?: number | null;
  placeholder?: string;
  hide?: boolean;
  disabled?: boolean;
  onChange?: (name: string, value: number) => void;
};

const ProgressInput = ({
  name,
  total,
  percent,
  percentValue,
  placeholder,
  value,
  hide,
  disabled,
  onChange,
}: ProgressInputType) => {
  const [inputValue, setInputValue] = useState<
    string | number | undefined | null
  >();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = el.target.value;

    setInputValue(newValue);
    onChange?.(name, Number(newValue));
  };

  return !hide ? (
    <div className="flex flex-col space-y-1">
      <div className={`flex flex-row items-center space-x-2`}>
        <input
          className="w-full rounded-lg border border-gray-300 bg-background p-1.5 text-xs text-label focus:border-primary disabled:pointer-events-none disabled:opacity-50"
          disabled={disabled}
          placeholder={placeholder}
          value={inputValue ?? ""}
          type="number"
          onChange={handleChange}
        />
        <div className="text-xs">{percentValue ?? 0}</div>
        <div className="text-xs">od</div>
        <div className="text-xs">{total ?? 0}</div>
      </div>
      {percentValue && (
        <div className="flex items-center space-x-1">
          <progress
            className="h-4 w-full"
            value={percentValue}
            max={total ?? 0}
          />
          <div className="mr-1 text-xs">
            {Number(percent) > 100 ? 100 : percent ?? 0}%
          </div>
          <TooltipWrapper
            button={
              <div>
                <BsInfoCircle className="cursor-pointer" />
              </div>
            }
            text="Procenat ukupno odstreljenih divljači"
          />
        </div>
      )}
    </div>
  ) : (
    <Text variant="table">-</Text>
  );
};

export default ProgressInput;
