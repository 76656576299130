import { useQuery } from "@/common/hooks/useQuery";
import PageLayout from "@/components/layout/PageLayout";
import TableFilterForm from "@/components/table/TableFilterForm";
import { filterFields, filterQueries } from "./statistics-fields";
import { useEffect, useRef, useState } from "react";
import Card from "@/components/Card";
import { ReportsService } from "@/common/API/ReportsService";
import Utils from "@/common/utils/Utils";
import { StatisticsData } from "@/common/types/Reports";
import { DropdownData } from "@/common/types/Form";
import { FormikProps } from "formik";

const StatisticsList = () => {
  const [data, setData] = useState<StatisticsData>();
  const [seasons, setSeasons] = useState<DropdownData[]>();

  const { query, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const filtersFormRef = useRef<FormikProps<StatisticsData>>(null);

  const getStatistics = async () => {
    const response = await ReportsService.get_statistics(
      Utils.formatQueryString(query),
    );

    const availableSeasons: DropdownData[] =
      response?.data?.data?.available_seasons.map((season: string) => ({
        name: season,
        id: season,
      }));

    setSeasons(availableSeasons);
    setData(response?.data?.data);
  };

  useEffect(() => {
    getStatistics();
  }, [query]);

  return (
    <PageLayout title={`Statistika`}>
      <div className="flex flex-col space-y-4">
        <div className="grid grid-cols-12 gap-4">
          <Card
            title="Broj položenih lovačkih ispita"
            content={
              data?.report?.exam_candidates_count?.length === 0 ? (
                <span className="text-sm font-medium">
                  Nema zabeleženih podataka
                </span>
              ) : (
                <div className="grid grid-cols-3 gap-4">
                  {data?.report?.exam_candidates_count?.map((el, index) => (
                    <div className="col-span-1 w-full" key={index}>
                      <span className="text-sm uppercase text-gray-400">
                        {el?.year}:
                      </span>
                      <h2 className="text-xl font-medium">
                        {el?.candidates_count}
                      </h2>
                    </div>
                  ))}
                </div>
              )
            }
            isLoading={!data}
          />
        </div>
        <TableFilterForm
          showFilters={true}
          filterFields={filterFields(seasons)}
          onSubmit={setFilters}
          formRef={filtersFormRef}
        />
        <div className="grid grid-cols-12 gap-4">
          <Card
            title="Ukupan broj korisnika lovišta"
            content={
              <div>
                <h2 className="text-xl font-medium">
                  {data?.report?.organization_count}
                </h2>
              </div>
            }
            isLoading={!data}
          />
          <Card
            title="Ukupan broj odobrenih lovnih karti"
            content={
              <div>
                <h2 className="text-xl font-medium">{data?.total_cards}</h2>
              </div>
            }
            isLoading={!data}
          />
          <Card
            title="Ukupan broj lovišta"
            content={
              <div>
                <h2 className="text-xl font-medium">
                  {data?.report?.hunting_ground_count}
                </h2>
              </div>
            }
            isLoading={!data}
          />
          <Card
            title="Ukupan broj izdatih markica"
            content={
              <div>
                <h2 className="text-xl font-medium">
                  {data?.report?.delivered_stamp_count}
                </h2>
              </div>
            }
            isLoading={!data}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default StatisticsList;
