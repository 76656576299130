import { useAuth } from "@/common/context/AuthProvider";
import { useNavigate } from "react-router";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import FormFactory from "@/components/forms/FormFactory";
import { huntingCardOrderCreateFields } from "./hunting-card-orders-fields";
import { useEffect, useRef, useState } from "react";
import { CreateHuntingCardOrderSchema } from "@/common/schemas/Hunting";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { TiPlusOutline } from "react-icons/ti";
import { HuntingOrderData } from "@/common/types/HuntingOrders";
import { FormikProps } from "formik";

const HuntingCardOrdersAdd = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginData } = useAuth();
  const navigate = useNavigate();

  const formRef =
    useRef<
      FormikProps<
        HuntingOrderData | { organization: string; association: string }
      >
    >(null);

  const onSubmit = async (values: HuntingOrderData) => {
    try {
      setIsLoading(true);
      const { statement, authorization, payment_slip, user_note } = values;
      const formData = new FormData();

      formData.append("statement", statement ?? null);
      formData.append("authorization", authorization ?? null);
      formData.append("payment_slip", payment_slip ?? null);
      formData.append("user_note", user_note);

      const response = await HuntingCardOrdersService.create(formData);

      navigate(`/hunting-card-orders/create/${response?.data?.data?.id}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    formRef?.current?.setValues({
      organization: loginData?.data?.organization?.name ?? "",
      association: loginData?.data?.association?.name ?? "",
    });
  }, []);

  return (
    <PageLayout
      backUrl="./../"
      title="Kreiranje lovne karte"
      buttons={
        <Button
          type="button"
          isLoading={isLoading}
          onClick={() => formRef?.current?.handleSubmit()}
        >
          Kreiraj
          <TiPlusOutline className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={huntingCardOrderCreateFields()}
        onSubmit={onSubmit}
        formRef={formRef}
        validationSchema={CreateHuntingCardOrderSchema}
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersAdd;
