import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TablePaginationProps } from "@/common/types/Table";
import TooltipWrapper from "../TooltipWrapper";

function TablePagination(props: TablePaginationProps) {
  const { page, totalPages, perPage, paginationMethods } = props;
  const pageSizeOptions = [10, 20, 30, 40, 50];

  return (
    <div className="flex w-full flex-col-reverse items-center gap-4 overflow-auto sm:flex-row sm:gap-8">
      <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:gap-6 lg:gap-8">
        <div className="flex items-center space-x-2">
          <p className="whitespace-nowrap text-sm text-gray-400">
            Redovi po strani
          </p>
          <Select
            value={String(perPage)}
            onValueChange={paginationMethods?.setPerPage}
          >
            <SelectTrigger className="h-8 w-[4.5rem] text-sm text-gray-500">
              <SelectValue placeholder="Redovi" />
            </SelectTrigger>
            <SelectContent side="top">
              {pageSizeOptions.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center justify-center text-sm text-gray-400">
          Strana {page} od {totalPages}
        </div>
        <div className="flex items-center space-x-2">
          <TooltipWrapper
            button={
              <Button
                aria-label="Go to first page"
                variant="outline"
                className="hidden size-8 p-0 lg:flex"
                onClick={paginationMethods?.setFirstPage}
                disabled={Number(page) === 1}
              >
                <DoubleArrowLeftIcon className="size-4" aria-hidden="true" />
              </Button>
            }
            text="Prva strana"
          />
          <TooltipWrapper
            button={
              <Button
                aria-label="Go to previous page"
                variant="outline"
                size="icon"
                className="size-8"
                onClick={paginationMethods?.decreasePage}
                disabled={Number(page) === 1}
              >
                <ChevronLeftIcon className="size-4" aria-hidden="true" />
              </Button>
            }
            text="Predhodna strana"
          />
          <TooltipWrapper
            button={
              <Button
                aria-label="Go to next page"
                variant="outline"
                size="icon"
                className="size-8"
                onClick={() => paginationMethods?.increasePage?.(totalPages)}
                disabled={Number(page) === totalPages}
              >
                <ChevronRightIcon className="size-4" aria-hidden="true" />
              </Button>
            }
            text="Sledeća strana"
          />
          <TooltipWrapper
            button={
              <Button
                aria-label="Go to last page"
                variant="outline"
                size="icon"
                className="hidden size-8 lg:flex"
                onClick={() => paginationMethods?.setLastPage?.(totalPages)}
                disabled={Number(page) === totalPages}
              >
                <DoubleArrowRightIcon className="size-4" aria-hidden="true" />
              </Button>
            }
            text="Poslednja strana"
          />
        </div>
      </div>
    </div>
  );
}

export default TablePagination;
