import { useNavigate } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useEffect, useRef, useState } from "react";
import { OrganizationsService } from "@/common/API/OrganizationsService";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { huntingGroundsFields } from "./hunting-grounds-fields";
import { HuntingGround } from "@/common/types/HuntingGrounds";
import { CreateHuntingGroundsSchema } from "@/common/schemas/HuntingGrounds";
import { Button } from "@/components/ui/button";
import PageLayout from "@/components/layout/PageLayout";
import { FiSave } from "react-icons/fi";
import { DropdownData } from "@/common/types/Form";
import { FormikProps } from "formik";

const HuntingGroundsAdd = () => {
  const [organizations, setOrganizations] = useState<DropdownData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<HuntingGround>>(null);

  const getOrganizations = async () => {
    const response = await OrganizationsService.all_dropdown();

    setOrganizations(response?.data?.data);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const onSubmit = async (values: HuntingGround) => {
    try {
      setIsLoading(true);
      await HuntingGroundsService.create(values);

      navigate(`/hunting-grounds`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      backUrl="./../"
      title="Kreiraj lovište"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={huntingGroundsFields(organizations)}
        onSubmit={onSubmit}
        validationSchema={CreateHuntingGroundsSchema}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default HuntingGroundsAdd;
