import { useNavigate, useParams } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useEffect, useRef, useState } from "react";
import { UsersService } from "@/common/API/UsersService";
import { IUser, Role, UserData } from "@/common/types/User";
import { usersEditFields } from "./users-fields";
import { EditUserSchema } from "@/common/schemas/Users";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import { useAuth } from "@/common/context/AuthProvider";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";
import { DropdownData } from "@/common/types/Form";
import { AssociationsService } from "@/common/API/AssociationsService";
import { OrganizationsService } from "@/common/API/OrganizationsService";

const UsersEdit = () => {
  const [data, setData] = useState<IUser>();
  const [role, setRole] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [associations, setAssociations] = useState<DropdownData[]>([]);
  const [organizations, setOrganizations] = useState<DropdownData[]>([]);

  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);

  const navigate = useNavigate();
  const { id } = useParams();
  const isPasswordRequired = false;
  const formRef = useRef<FormikProps<UserData>>(null);

  const getUserData = async () => {
    const response = await UsersService.one(id);
    const data = response?.data?.data;

    setData(data);
    setRole(data?.roles?.find((role: Role) => role?.id != ROLES.Examiner)?.id);
  };

  const getAssociations = async () => {
    const response = await AssociationsService.all_dropdown();
    const filteredData = response?.data?.data.filter(
      (el: DropdownData) => el.id !== -1,
    );
    setAssociations(filteredData);
  };

  const getOrganizations = async (associationId?: string | number) => {
    const response = await OrganizationsService.all_dropdown(associationId);

    setOrganizations(response?.data?.data);
  };

  const onRoleChange = (roleId?: string) => {
    setRole(Number(roleId));

    formRef?.current?.setFieldValue("association_id", "");
    formRef?.current?.setFieldValue("organization_id", "");
    Number(roleId) === ROLES.Agency
      ? getOrganizations("-1")
      : getOrganizations();
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getAssociations();
    getOrganizations(data?.association?.id ?? "-1");
  }, [data]);

  const onSubmit = async (values: UserData) => {
    setIsLoading(true);
    try {
      const {
        password,
        password_confirmation,
        role_id,
        is_examiner,
        ...otherValues
      } = values;

      const data = {
        ...otherValues,
        association_id:
          Number(otherValues.association_id) === -1
            ? null
            : otherValues.association_id,
        role_ids: role_id ? [Number(role_id)] : [],
        ...(password !== "" && { password }),
        ...(password !== "" && {
          password_confirmation,
        }),
      };

      if (is_examiner) {
        data.role_ids.push(ROLES.Examiner);
      }

      await UsersService.update(id, data);
      navigate(`/users`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      title="Izmeni korisnika"
      backUrl="./../"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
          hide={!isAdmin}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      {data && (
        <FormFactory
          formFields={usersEditFields(
            associations,
            organizations,
            role,
            onRoleChange,
            getOrganizations,
            isPasswordRequired,
            data,
          )}
          onSubmit={onSubmit}
          validationSchema={EditUserSchema}
          formRef={formRef}
        />
      )}
    </PageLayout>
  );
};

export default UsersEdit;
