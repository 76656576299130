import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email nije validan").required("Unesite email"),
  password: Yup.string().required("Unesite lozinku"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email nije validan").required("Unesite email"),
});

export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Lozinka mora biti duža od 8 karaktera")
    .max(20, "Lozinka mora biti kraća od 20 karaktera")
    .matches(/[A-Z]/, "Lozinka mora da ima veliko slovo")
    .matches(/[a-z]/, "Lozinka mora da ima malo slovo")
    .matches(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      "Mora sadržati jedan specijalni karakter",
    )
    .required("Unesite lozinku"),
  password_confirmation: Yup.string()
    .required("Unesite lozinku ponovo")
    .oneOf([Yup.ref("password"), null!], "Lozinke moraju biti iste"),
});
