import { DropdownData } from "@/common/types/Form";
import Checkbox from "@/components/forms/Checkbox";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import Input from "@/components/forms/Input";
import StatusTag from "@/components/StatusTag";
import TooltipWrapper from "@/components/TooltipWrapper";

export const tableColumns = (
  selectedRows?: number[],
  selectAll?: boolean,
  onRowSelect?: (id: number) => void,
  onRowSelectAll?: () => void,
  query?: Record<string, string>,
) => {
  return [
    {
      title: "Select All",
      dataIndex: "select",
      key: "select",
      renderHeader: () =>
        query?.without_hunting_plan === "0" ? (
          <TooltipWrapper
            button={
              <div>
                <Checkbox
                  value={selectAll}
                  callback={() => onRowSelectAll?.()}
                />
              </div>
            }
            text="Selektuj sve redove"
            className="normal-case"
          />
        ) : (
          <></>
        ),
      render: ({
        id,
        has_hunting_plan,
      }: {
        id: number;
        has_hunting_plan: boolean;
      }) =>
        has_hunting_plan ? (
          <TooltipWrapper
            button={
              <div>
                <Checkbox
                  value={selectAll ? true : selectedRows?.includes(id)}
                  callback={() => onRowSelect?.(id)}
                  disabled={selectAll}
                />
              </div>
            }
            text="Selektuj red"
            className="normal-case"
          />
        ) : (
          <div>
            <Checkbox value={false} disabled={true} />
          </div>
        ),
    },
    {
      title: "Naziv lovišta",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Naziv korisnika lovišta",
      dataIndex: ["organization", "name"],
      key: ["organization", "name"],
    },
    {
      title: "Mesto",
      dataIndex: ["organization", "residence"],
      key: ["organization", "residence"],
    },
    {
      title: "Godišnji plan",
      dataIndex: "has_hunting_plan",
      key: "has_hunting_plan",
      render: ({ has_hunting_plan }: { has_hunting_plan: boolean }) =>
        has_hunting_plan ? (
          <StatusTag type="accepted" title="Dostavljen" />
        ) : (
          <StatusTag type="rejected" title="Nije dostavljen" />
        ),
    },
  ];
};

export const filterFields = (
  associations?: DropdownData[],
  organizations?: DropdownData[],
) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Naziv lovišta",
        name: "name",
        placeholder: "Pretraži po nazivu lovišta",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez/Agencija",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta",
        options: organizations ?? [],
        value: "",
        disabled: organizations?.length === 0,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Godišnji plan",
        name: "without_hunting_plan",
        placeholder: "Izaberite",
        options: [
          { name: "Nije dostavljen", id: 1 },
          { name: "Dostavljen", id: 0 },
        ],
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  name: "",
  association_id: "",
  organization_id: "",
  without_hunting_plan: "",
  sort: "",
  order: "",
};
