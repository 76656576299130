import config from "../config/config";
import { axios } from "../config/axios";
import { WarehouseHuntingCardCreateData } from "../types/Warehouse";

export const WarehouseHuntingCardsService = {
  async all(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/warehouse-hunting-cards?${query}`,
    );

    return response;
  },

  async create(data: WarehouseHuntingCardCreateData) {
    const response = await axios.post(
      `${config.baseUrl}/warehouse-hunting-cards`,
      data,
    );

    return response;
  },

  async update(id?: string, data?: WarehouseHuntingCardCreateData) {
    const response = await axios.patch(
      `${config.baseUrl}/warehouse-hunting-cards/${id}`,
      data,
    );

    return response;
  },

  async delete(id: string) {
    const response = await axios.delete(
      `${config.baseUrl}/warehouse-hunting-cards/${id}`,
    );

    return response;
  },

  async getLastNumber() {
    const response = await axios.get(
      `${config.baseUrl}/warehouse-hunting-cards/last-to-number`,
    );

    return response;
  },
};
