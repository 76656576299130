import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "./utils";
import { IoReload } from "react-icons/io5";

const buttonVariants = cva(
  "inline-flex rounded-sm items-center justify-center whitespace-nowrap text-xs font-medium transition-colors focus-visible:outline-none  disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-text shadow hover:bg-primary-hover",
        destructive:
          "bg-destructive text-text shadow-sm hover:bg-destructive/90",
        outline: "bg-gray-50 shadow-sm hover:bg-gray-100",
        secondary: "bg-secondary text-text shadow hover:bg-secondary-hover",
        ghost: "text-secondary bg-secondary-foreground/20",
        link: "text-primary underline-offset-4 hover:underline",
        modalConfirm: "p-2 bg-primary text-white",
        modalDecline: "p-2 border border-gray-300",
        dropdown:
          "rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label focus:border-primary disabled:pointer-events-none disabled:opacity-50",
      },
      size: {
        default: "h-9 px-3 py-2",
        sm: "h-8 px-3 text-xs",
        lg: "h-10 px-8",
        icon: "h-7 w-7",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  hide?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      isLoading,
      hide,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const [childrenElements, setChildrenElements] = React.useState(children);
    React.useEffect(() => {
      if (isLoading) {
        setChildrenElements(<IoReload className="animate-spin" />);
      } else {
        setChildrenElements(children);
      }
    }, [isLoading]);

    return !hide ? (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading}
        children={<>{childrenElements}</>}
        {...props}
      />
    ) : null;
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
