const CONSTANTS = {
  months: [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "Avgust",
    "Septembar",
    "Octobar",
    "Novembar",
    "Decembar",
  ],
  monthObjects: [
    { name: "April", id: 4 },
    { name: "Maj", id: 5 },
    { name: "Jun", id: 6 },
    { name: "Jul", id: 7 },
    { name: "Avgust", id: 8 },
    { name: "Septembar", id: 9 },
    { name: "Oktobar", id: 10 },
    { name: "Novembar", id: 11 },
    { name: "Decembar", id: 12 },
    { name: "Januar", id: 1 },
    { name: "Februar", id: 2 },
    { name: "Mart", id: 3 },
  ],
  roleObjects: (hasPresident = false) => [
    { name: "LSS Admin", id: 1, className: "bg-blue-100 text-blue-500" },
    { name: "Admin", id: 2, className: "bg-amber-100 text-amber-500" },
    {
      name: "Korisnik lovišta",
      id: 3,
      className: "bg-red-100 text-red-500",
    },
    { name: "Agencija", id: 4, className: "bg-green-100 text-green-500" },
    {
      name: "Inspektor",
      id: 5,
      className: "bg-violet-100 text-violet-500",
    },
    { name: "Lovočuvar", id: 6, className: "bg-lime-100 text-lime-500" },
    {
      name: "LSS Moderator",
      id: 7,
      className: "bg-yellow-100 text-yello-500",
    },
    ...(hasPresident ? [{ name: "Predsednik komisije", id: 8 }] : []),
  ],
  huntingCardOrderStatus: [
    { name: "U doradi", id: 1 },
    { name: "Neodgovoreno", id: 2 },
    { name: "Odbijeno", id: 3 },
    { name: "Odobreno", id: 4 },
  ],
  stampOrderStatus: [
    { name: "Neodgovoreno", id: 1 },
    { name: "Odgovoreno", id: 2 },
  ],
  huntingAssociations: [
    {
      id: 6,
      name: "LSKIM",
      fullName: "Lovački savez Kosova i Metohije",
      className: "bg-blue-100 text-blue-500",
    },
    {
      id: 5,
      name: "LSJIS",
      fullName: "Lovački savez Jugoistočne Srbije",
      className: "bg-red-100 text-red-500",
    },
    {
      id: 4,
      name: "LSZS",
      fullName: "Lovački savez Zapadne Srbije",
      className: "bg-amber-100 text-amber-500",
    },
    {
      id: 3,
      name: "LSCS",
      fullName: "Lovački savez Centralne Srbije",
      className: "bg-green-100 text-green-500",
    },
    {
      id: 2,
      name: "LSV",
      fullName: "Lovački savez Vojvodine",
      className: "bg-yellow-100 text-yellow-500",
    },
    {
      id: 1,
      name: "LSS",
      fullName: "Lovački savez Srbije",
      className: "bg-sky-100 text-sky-500",
    },
  ],
  dateFormat: "dd/MM/yyyy",
};

export default CONSTANTS;
