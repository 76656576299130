import config from "../config/config";
import { axios } from "../config/axios";
import { WarehouseStampCreateData } from "../types/Warehouse";

export const WarehouseStampsService = {
  async all(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/warehouse-stamps?${query}`,
    );

    return response;
  },

  async create(data: WarehouseStampCreateData) {
    const response = await axios.post(
      `${config.baseUrl}/warehouse-stamps`,
      data,
    );

    return response;
  },

  async update(id?: string, data?: WarehouseStampCreateData) {
    const response = await axios.patch(
      `${config.baseUrl}/warehouse-stamps/${id}`,
      data,
    );

    return response;
  },

  async delete(id: string) {
    const response = await axios.delete(
      `${config.baseUrl}/warehouse-stamps/${id}`,
    );

    return response;
  },

  async getLastNumber(animal_id: string, stamp_type: string) {
    const response = await axios.get(
      `${config.baseUrl}/warehouse-stamps/last-to-number?animal_id=${animal_id}&stamp_type=${stamp_type}`,
    );

    return response;
  },
};
