import Router from "./components/Router";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLoadUserFromLocalStorage from "./common/hooks/useLoadUserFromLocalStorage";
import ScreenLoader from "./components/loaders/ScreenLoader";
import { AnimatePresence } from "framer-motion";

function App() {
  const { isLocalStorageLoaded } = useLoadUserFromLocalStorage();

  if (!isLocalStorageLoaded) {
    return <ScreenLoader />;
  }

  return (
    <div>
      <main>
        <AnimatePresence>
          <Router />
        </AnimatePresence>

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
          theme="colored"
          className="!p-3 "
          transition={Flip}
        />
      </main>
    </div>
  );
}

export default App;
