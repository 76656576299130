import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { CheckboxInput } from "../ui/checkbox";

type CheckboxType = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: boolean;
  callback?: () => void;
  className?: string;
};

const Checkbox = ({
  field,
  form,
  label,
  placeholder,
  disabled,
  value,
  callback,
  className,
  ...props
}: CheckboxType) => {
  return (
    <div className={`flex items-center space-x-1.5 self-end ${className}`}>
      <CheckboxInput
        id="checkbox"
        name={field?.name}
        checked={field?.value ? field?.value : value}
        onCheckedChange={(value) => {
          form?.setFieldValue(field?.name!, value);
          callback?.();
        }}
        disabled={disabled}
      />
      <label htmlFor="checkbox" className="cursor-pointer select-none text-xs">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
