import CONSTANTS from "@/common/config/constants";
import { StampOrderStatus } from "@/common/enums/stamps";
import { DropdownData } from "@/common/types/Form";
import { HuntingGround } from "@/common/types/HuntingGrounds";
import Utils from "@/common/utils/Utils";
import StatusTag from "@/components/StatusTag";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import TooltipWrapper from "@/components/TooltipWrapper";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";

export const tableColumns = [
  {
    title: "Broj zahteva",
    dataIndex: "id",
    key: "id",
    sortOptions: {
      sortDirection: "",
      sortParam: "id",
    },
  },
  {
    title: "Lovište",
    dataIndex: ["hunting_ground", "name"],
    key: ["hunting_ground", "name"],
  },
  {
    title: "Korisnik lovišta",
    dataIndex: ["hunting_ground", "organization", "name"],
    key: ["hunting_ground", "organization", "name"],
  },
  {
    title: "Mesto",
    dataIndex: ["hunting_ground", "organization", "residence"],
    key: ["hunting_ground", "organization", "residence"],
  },
  {
    title: "Savez",
    dataIndex: ["hunting_ground", "organization", "association", "name"],
    key: ["hunting_ground", "organization", "association", "name"],
    render: ({ hunting_ground }: { hunting_ground: HuntingGround }) => {
      const a = CONSTANTS.huntingAssociations.find(
        (a) => a?.id === hunting_ground?.organization?.association?.id,
      );

      return hunting_ground?.organization?.association ? (
        <TooltipWrapper
          button={
            <div className="flex flex-row items-center space-x-1">
              <Tag title={a?.name} className={a?.className} />
            </div>
          }
          text={a?.fullName!}
        />
      ) : (
        <Text variant="table">-</Text>
      );
    },
  },
  {
    title: "Sezona",
    dataIndex: "season",
    key: "season",
    sortOptions: {
      sortDirection: "",
      sortParam: "season",
    },
  },
  {
    title: "Datum podnošenja",
    dataIndex: "created_at",
    key: "created_at",
    render: ({ created_at }: { created_at: string }) => (
      <Text variant="table">{Utils.convertTime(created_at)}</Text>
    ),
    sortOptions: {
      sortDirection: "",
      sortParam: "created_at",
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ status }: { status: number }) => {
      switch (status) {
        case StampOrderStatus.Unanswered:
          return <StatusTag type="in-progress" title="Neodgovoreno" />;
        case StampOrderStatus.Answered:
          return <StatusTag type="accepted" title="Odgovoreno" />;
      }
    },
    sortOptions: {
      sortDirection: "",
      sortParam: "status",
    },
  },
];

export const filterFields = (
  associations?: DropdownData[],
  organizations?: DropdownData[],
  hasAssociation?: boolean,
) => {
  return [
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
        hide: hasAssociation,
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta",
        options: organizations ?? [],
        value: "",
        disabled: organizations?.length === 0,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Status",
        name: "status",
        placeholder: "Izaberi status",
        options: CONSTANTS.stampOrderStatus,
        value: "",
      },
    },
  ];
};
export const filterQueries = {
  association_id: "",
  organization_id: "",
  status: "",
  sort: "",
  order: "",
};
