import Utils from "@/common/utils/Utils";
import DatePicker from "@/components/forms/DatePicker";
import Dropdown from "@/components/forms/Dropdown";
import FileUpload from "@/components/forms/FileUpload";
import Input from "@/components/forms/Input";
import Text from "@/components/Text";
import { HuntingExamsStatus } from "@/common/enums/hunting";
import StatusTag from "@/components/StatusTag";
import CONSTANTS from "@/common/config/constants";

export const tableColumns = [
  {
    title: "Datum polaganja",
    dataIndex: "exam_date",
    key: "exam_date",
    render: ({ exam_date }: { exam_date: string }) => (
      <Text variant="table">{Utils.convertTime(exam_date)}</Text>
    ),
    sortOptions: {
      sortDirection: "",
      sortParam: "exam_date",
    },
  },
  {
    title: "Organizator ispita",
    dataIndex: "organizer",
    key: "organizer",
  },
  {
    title: "Mesto",
    dataIndex: "place_of_exam",
    key: "place_of_exam",
    sortOptions: {
      sortDirection: "",
      sortParam: "place_of_exam",
    },
  },
  {
    title: "Broj kandidata",
    dataIndex: "candidate_count",
    key: "candidate_count",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ status }: { status: number }) => {
      switch (status) {
        case HuntingExamsStatus.Open:
          return <StatusTag type="open" title="U doradi" />;
        case HuntingExamsStatus.Sent:
          return <StatusTag type="in-progress" title="Neodgovoreno" />;
        case HuntingExamsStatus.Rejected:
          return <StatusTag type="rejected" title="Odbijeno" />;
        case HuntingExamsStatus.Accepted:
          return <StatusTag type="accepted" title="Odobreno" />;
      }
    },
    sortOptions: {
      sortDirection: "",
      sortParam: "status",
    },
  },
];

export const filterFields = [
  {
    controlType: Input,
    className: "md:col-span-3 col-span-12",
    options: {
      label: "Organizator ispita",
      name: "organizer",
      placeholder: "Pretraži po organizatoru ispita",
      focus: true,
      value: "",
    },
  },
  {
    controlType: Dropdown,
    className: "md:col-span-3 col-span-12",
    options: {
      label: "Status",
      name: "status",
      placeholder: "Izaberi status",
      options: CONSTANTS.huntingCardOrderStatus,
      value: "",
    },
  },
];

export const filterQueries = {
  organizer: "",
  status: "",
  sort: "",
  order: "",
};

export const examsCreateFields = (disableInputs?: boolean) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Podnosilac zahteva",
        name: "creator",
        placeholder: "Podnosilac zahteva",
        value: "",
        required: true,
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Organizator ispita",
        name: "organizer",
        placeholder: "Unesite organizatora",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Mesto polaganja",
        name: "place_of_exam",
        placeholder: "Unesite mesto polaganja",
        value: "",
        required: true,
        disabled: disableInputs,
      },
    },
    {
      controlType: DatePicker,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Datum",
        name: "exam_date",
        placeholder: "Unesite datum",
        value: new Date().toISOString().split("T")[0] ?? "",
        required: true,
        disabled: disableInputs,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Član komisije",
        name: "examiner1",
        placeholder: "Unesite člana komisije",
        value: "",
        required: true,
        disabled: disableInputs,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Član komisije",
        name: "examiner2",
        placeholder: "Unesite člana komisije",
        value: "",
        required: true,
        disabled: disableInputs,
      },
    },
    {
      controlType: FileUpload,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: {
        label: "Rešenje ispita",
        name: "solution_document",
        accept: "image/*, .pdf",
        required: true,
        disabled: disableInputs,
      },
    },
  ];
};
