import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import { tableColumns, filterQueries, filterFields } from "./candidate-fields";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { ExamsService } from "@/common/API/ExamsService";
import { Candidate, ExamData } from "@/common/types/Exams";
import TableActionButton from "@/components/table/TableActionButton";
import { BsFiletypePdf } from "react-icons/bs";

const ExamsDocumentsList = () => {
  const [data, setData] = useState<ExamData | []>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });

  const getCandidateExams = async () => {
    const response = await ExamsService.getCandidateExams(
      Utils.formatQueryString(query),
    );

    setData({
      data: response?.data?.data,
      pagination: response?.data?.pagination,
    });
  };

  const onGeneratePdf = async ({
    examId,
    candidateId,
    withBackground,
  }: {
    examId: string;
    candidateId?: string;
    withBackground?: boolean;
  }) => {
    const response = await ExamsService.getCertificatePdf({
      examId,
      candidateId,
      withBackground,
    });

    Utils.openDocument(response?.data?.data);
  };

  const actionButtons = (candidate?: Candidate) => (
    <TableActionButton
      icon={
        <BsFiletypePdf
          size={16}
          onClick={() =>
            onGeneratePdf({
              examId: String(candidate?.hunting_exam?.id),
              candidateId: String(candidate?.id),
              withBackground: true,
            })
          }
        />
      }
      tooltipText="Uverenje PDF"
    />
  );

  useEffect(() => {
    getCandidateExams();
  }, [query]);

  return (
    <PageLayout title="Pretraga diplome" backUrl="./../">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields}
        filterQueries={filterQueries}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        hideDelete={true}
        hideEdit={true}
        onPrint={(candidate: Candidate) =>
          onGeneratePdf({
            examId: String(candidate?.hunting_exam?.id),
            candidateId: String(candidate?.id),
            withBackground: false,
          })
        }
        actionButtons={actionButtons}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default ExamsDocumentsList;
