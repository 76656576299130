import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const PopoverButton = ({
  className,
  button,
  content,
}: {
  button?: JSX.Element;
  content?: JSX.Element;
  className: string;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>{button}</PopoverTrigger>
      <PopoverContent className={className}>{content}</PopoverContent>
    </Popover>
  );
};

export default PopoverButton;
