import Datepicker from "react-datepicker";
import { sr } from "date-fns/locale/sr";
import "react-datepicker/dist/react-datepicker.css";
import { FieldInputProps, FieldProps, FormikProps } from "formik";
import InputRequiredTooltip from "./InputRequiredTooltip";
import CONSTANTS from "@/common/config/constants";

type DatePickerProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  focus?: boolean;
};

type DatePickerFormProps = FieldProps<any> & DatePickerProps;

const DatePicker = ({
  field,
  form,
  placeholder,
  label,
  disabled,
  required,
  focus,
  className,
  ...props
}: DatePickerFormProps | DatePickerProps) => {
  const error = form?.errors?.[field?.name!];

  return (
    <div className={`${className} relative`}>
      {label && (
        <div className="flex flex-row items-center space-x-1">
          <label className="mb-2 block text-xs text-label">{label}</label>
          {required && <InputRequiredTooltip label="Polje je obavezno" />}
        </div>
      )}

      <Datepicker
        className={`w-full rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label focus:border-primary disabled:pointer-events-none disabled:opacity-50 ${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""}`}
        wrapperClassName="w-full"
        locale={sr}
        selected={field?.value ?? new Date()}
        dateFormat={CONSTANTS.dateFormat}
        onKeyDown={(e) => e.preventDefault()}
        onChange={(date) => {
          form?.setFieldValue(field?.name!, date?.toISOString().split("T")[0]);
        }}
        placeholderText={placeholder}
        disabled={disabled}
        autoFocus={focus}
      />
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default DatePicker;
