import config from "../config/config";
import { axios } from "../config/axios";
import { Candidate } from "../types/Exams";

export const ExamsService = {
  async all(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-exams?${query}`,
    );

    return response;
  },

  async one(id?: string) {
    const response = await axios.get(`${config.baseUrl}/hunting-exams/${id}`);

    return response;
  },

  async create(data: FormData) {
    const response = await axios.post(`${config.baseUrl}/hunting-exams`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  },

  async update(id?: string, data?: FormData) {
    const resposne = await axios.post(
      `${config.baseUrl}/hunting-exams/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return resposne;
  },

  async delete(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/hunting-exams/${id}`,
    );

    return response;
  },

  async send(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-exams/${id}/send`,
    );

    return response;
  },

  async accept(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-exams/${id}/accept`,
    );

    return response;
  },

  async decline(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-exams/${id}/return`,
    );

    return response;
  },

  async getCandidateExams(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-exam-candidates?${query}`,
    );

    return response;
  },

  async createCandidate(data?: Candidate) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-exam-candidates`,
      data,
    );

    return response;
  },

  async updateCandidate(candidateId?: number, data?: Candidate) {
    const response = await axios.put(
      `${config.baseUrl}/hunting-exam-candidates/${candidateId}`,
      data,
    );

    return response;
  },

  async deleteCandidate(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/hunting-exam-candidates/${id}`,
    );

    return response;
  },

  async getCertificatePdf({
    examId,
    candidateId,
    withBackground,
  }: {
    examId?: string;
    candidateId?: string;
    withBackground?: boolean;
  }) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-exams/${examId}/candidates/${candidateId}/pdf?with_background=${withBackground}`,
    );

    return response;
  },
};
