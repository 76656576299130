import { RefObject } from "react";
import FormFactory from "../forms/FormFactory";
import { FormikProps } from "formik";

type TableFilterFormProps = {
  showFilters: boolean;
  filterFields: any;
  onSubmit: (values: any) => void;
  formRef: RefObject<FormikProps<any>>;
};

const TableFilterForm = ({
  showFilters,
  filterFields,
  onSubmit,
  formRef,
}: TableFilterFormProps) => {
  return showFilters ? (
    <>
      {filterFields && (
        <div className="flex items-center justify-between space-x-4 rounded-lg bg-white p-3">
          <div className="flex-1">
            <FormFactory
              formFields={filterFields}
              onSubmit={onSubmit}
              className="grid grid-cols-12 gap-4"
              formRef={formRef}
              autoSubmit={true}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default TableFilterForm;
