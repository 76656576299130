import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  huntingPlanRecordFields,
  tableColumns,
} from "./hunting-plan-records-fields";
import { useEffect, useRef, useState } from "react";
import { DropdownData } from "@/common/types/Form";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import {
  AnimalHuntingPlanRecord,
  AnimalHuntingRecordState,
  AnimalReportData,
} from "@/common/types/Animals";
import HuntingUtils from "@/common/utils/HuntingUtils";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";
import Modal from "@/components/Modal";
import FormFactory from "@/components/forms/FormFactory";

const HuntingPlanRecordsList = () => {
  const { loginData } = useAuth();
  const [data, setData] = useState<AnimalHuntingRecordState>({
    data: [],
    isMonthFilled: false,
    availableSeasons: [],
  });
  const [animalData, setAnimalData] = useState<AnimalReportData[]>([]);
  const [huntingGrounds, setHuntingGrouds] = useState<DropdownData[]>([]);
  const [huntingPlanRecord, setHuntingPlanRecord] =
    useState<AnimalHuntingPlanRecord>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const { query, setQuery, setFilters, resetQuery } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const isSuperAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
  ]);
  const isRegularAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Admin,
    ROLES.Moderator,
  ]);

  const huntingPlanRecordRef =
    useRef<FormikProps<AnimalHuntingPlanRecord>>(null);

  const getHuntingPlanRecordsData = async () => {
    if (query?.id === "") {
      setData({
        data: [],
        isMonthFilled: false,
        availableSeasons: [],
      });
      return;
    }

    const response = await HuntingGroundsService.getHuntingPlanRecord({
      id: query?.id,
      month: query?.month,
      season: query?.season,
    });

    const availableSeasons: DropdownData[] =
      response?.data?.data?.available_seasons.map((season: string) => ({
        name: season,
        id: season,
      }));

    if (availableSeasons?.length === 0 && query?.season !== "") {
      resetQuery("season");
    }

    setData({
      data: response?.data?.data?.realizations,
      isMonthFilled: response?.data?.data?.is_month_filled,
      availableSeasons,
    });
  };

  const getHuntingGrounds = async () => {
    const response = await HuntingGroundsService.all_dropdown();

    setHuntingGrouds(response?.data?.data);
  };

  const onSubmit = async (data: {
    season: string;
    month: string;
    animal_plan_realizations: AnimalReportData[];
  }) => {
    await HuntingGroundsService.createHuntingPlanRecord(query?.id, data);

    getHuntingPlanRecordsData();
  };

  useEffect(() => {
    getHuntingGrounds();
  }, []);

  useEffect(() => {
    getHuntingPlanRecordsData();
  }, [query]);

  const onInputChange = (
    animal_id: number,
    name: string,
    value: number,
    type: number,
  ) => {
    setAnimalData((prevData: AnimalReportData[]) =>
      HuntingUtils.handleAnimalReportInput(
        animal_id,
        name,
        value,
        type,
        prevData,
      ),
    );
  };

  const onEditHuntingPlan = async (values: {
    male: number;
    female: number;
    offspring: number;
    total: number;
  }) => {
    if (huntingPlanRecord?.realization_for_month?.id) {
      const formattedData = {
        ...values,
        animal_id: huntingPlanRecord?.animal?.id,
      };

      await HuntingGroundsService.updateHuntingPlanRecord(
        huntingPlanRecord?.realization_for_month?.id,
        formattedData,
      );
    } else {
      const formattedData = [
        {
          animal_id: Number(huntingPlanRecord?.animal?.id),
          ...(values?.male && { male: values?.male }),
          ...(values?.female && { female: values?.female }),
          ...(values?.offspring && { offspring: values?.offspring }),
          ...(values?.total && { total: values?.total }),
        },
      ];

      onSubmit({
        season: query?.season,
        month: query?.month,
        animal_plan_realizations: formattedData,
      });
    }

    getHuntingPlanRecordsData();
    setOpenEditModal(false);
  };

  return (
    <PageLayout
      title="Evidecija odstrela"
      buttons={
        <Button
          hide={
            data?.data?.length === 0 ||
            animalData?.length === 0 ||
            data?.isMonthFilled
          }
          onClick={() =>
            onSubmit({
              season: query?.season,
              month: query?.month,
              animal_plan_realizations: animalData,
            })
          }
        >
          {Utils.hasPermission(loginData?.data?.roles, [ROLES.User])
            ? "Sačuvaj"
            : "Izmeni"}
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <Table
        data={{ data: data?.data }}
        columns={tableColumns(
          onInputChange,
          data?.isMonthFilled,
          isSuperAdmin,
          isRegularAdmin,
        )}
        filterFields={filterFields(huntingGrounds, data?.availableSeasons)}
        filterQueries={filterQueries}
        hidePagination={true}
        hideActions={false}
        hideDelete={true}
        hidePrint={true}
        onEdit={(data) => {
          setOpenEditModal(!openEditModal);
          setHuntingPlanRecord(data);
        }}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
      />

      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni evidenciju"
        buttonTitle="Izmeni"
        onSubmit={() => huntingPlanRecordRef?.current?.handleSubmit()}
        content={
          <FormFactory
            formFields={huntingPlanRecordFields(
              huntingPlanRecord,
              data?.isMonthFilled,
            )}
            onSubmit={onEditHuntingPlan}
            formRef={huntingPlanRecordRef}
          />
        }
      />
    </PageLayout>
  );
};

export default HuntingPlanRecordsList;
