import config from "../config/config";
import { axios } from "../config/axios";

export const AnimalsService = {
  async all() {
    const response = await axios.get(`${config.baseUrl}/animals/all`);

    return response;
  },
  async allWithStamps() {
    const response = await axios.get(`${config.baseUrl}/animals/with-stamps`);

    return response;
  },
  async oneWithStamps(id: string) {
    const response = await axios.get(`${config.baseUrl}/animals/${id}/stamps`);

    return response;
  },
};
