import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Table from "@/components/table/Table";
import FormFactory from "@/components/forms/FormFactory";
import { examsCreateFields } from "./exams-fields";
import { createCandidateFields, tableColumns } from "./candidate-fields";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import { BsSend } from "react-icons/bs";
import { RxValueNone } from "react-icons/rx";
import { TiPlusOutline } from "react-icons/ti";
import { CgTrash } from "react-icons/cg";
import ConfirmationPopover from "@/components/ConfirmationPopover";
import Modal from "@/components/Modal";
import { Candidate, Exam } from "@/common/types/Exams";
import { ExamsService } from "@/common/API/ExamsService";
import FormUtils from "@/common/utils/FormUtils";
import useDropdownData from "@/common/hooks/useDropdownData";
import TableActionButton from "@/components/table/TableActionButton";
import { BsFiletypePdf } from "react-icons/bs";
import { HuntingExamsStatus } from "@/common/enums/hunting";
import { RiArrowGoBackFill } from "react-icons/ri";
import { CreateCandidateSchema } from "@/common/schemas/Exams";
import { useAuth } from "@/common/context/AuthProvider";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";

const ExamsEdit = () => {
  const [data, setData] = useState<Exam>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<Candidate>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const { organizations } = useDropdownData({
    hasAgency: true,
  });

  const { id } = useParams();
  const { loginData } = useAuth();
  const navigate = useNavigate();
  const isExaminer =
    loginData?.data?.roles?.filter((role) => role?.id === ROLES.Examiner)
      ?.length !== 0;

  const formRef = useRef<FormikProps<Exam>>(null);
  const candidateCreateFormRef = useRef<FormikProps<Candidate>>(null);
  const candidateEditFormRef = useRef<FormikProps<Candidate>>(null);

  const isExamSent = data?.status === HuntingExamsStatus.Sent;
  const isExamAccepted = data?.status === HuntingExamsStatus.Accepted;
  const disableInputs = isExamSent || isExamAccepted;

  const getExamData = async () => {
    const response = await ExamsService.one(id);
    const data = response?.data?.data;

    formRef.current?.setValues({
      ...data,
      creator: data?.creator?.name,
      organization_id: String(data?.organization?.id),
      examiner1: data?.members_of_commission?.[0],
      examiner2: data?.members_of_commission?.[1],
    });

    setData(data);
  };

  useEffect(() => {
    getExamData();
  }, [organizations]);

  const onSubmit = async (values: Exam) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      const { solution_document, ...examData } = values;

      FormUtils.appendDataToFormData(examData, formData);

      FormUtils.appendFilesToRequest(
        [solution_document] as File[],
        formData,
        "solution_document",
      );

      formData.append("_method", "PATCH");

      await ExamsService.update(id, formData);

      navigate(`./..`);
    } finally {
      setIsLoading(false);
    }
  };

  const onComplete = async () => {
    await ExamsService.send(id);
    navigate("./../");
  };

  const onCreateCandidate = async (values: Candidate) => {
    await ExamsService.createCandidate({ ...values, hunting_exam_id: id });

    getExamData();
    setOpenCreateModal(false);
  };

  const onGeneratePdf = async ({
    candidateId,
    withBackground,
  }: {
    candidateId?: string;
    withBackground?: boolean;
  }) => {
    try {
      setIsLoading(true);
      const response = await ExamsService.getCertificatePdf({
        examId: id,
        candidateId,
        withBackground,
      });

      Utils.openDocument(response?.data?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    await ExamsService.delete(id);
    navigate(`./../`);
  };

  const onReject = async () => {
    await ExamsService.decline(id);
    navigate("./../");
  };

  const onAccept = async () => {
    await ExamsService.accept(id);
    navigate("./../");
  };

  const onEditCandidate = async (candidateValues: Candidate) => {
    await ExamsService.updateCandidate(candidate?.id, {
      ...candidateValues,
      hunting_exam_id: id,
    });

    getExamData();
    setOpenEditModal(false);
  };

  const actionButtons = (candidate?: Candidate) => (
    <TableActionButton
      icon={
        <BsFiletypePdf
          size={16}
          onClick={() =>
            onGeneratePdf({
              candidateId: String(candidate?.id),
              withBackground: true,
            })
          }
        />
      }
      tooltipText="Uverenje PDF"
    />
  );

  return (
    <PageLayout
      backUrl="./../"
      title="Pregled ispita"
      buttons={
        <>
          <Button
            variant="secondary"
            onClick={onComplete}
            isLoading={isLoading}
            hide={!isExaminer || isExamSent || isExamAccepted}
          >
            Završi i pošalji ispit <BsSend className="ml-3" />
          </Button>

          <Button
            variant="secondary"
            onClick={onReject}
            isLoading={isLoading}
            hide={
              !Utils.hasPermission(loginData?.data?.roles, [ROLES.Moderator]) ||
              !isExamSent
            }
          >
            Vrati na doradu
            <RiArrowGoBackFill className="ml-3" />
          </Button>

          <ConfirmationPopover
            title="Da li ste sigurni? Ispit će biti obrisan."
            onDelete={onDelete}
            button={
              <div>
                <Button
                  variant="secondary"
                  isLoading={isLoading}
                  hide={!isExaminer || isExamAccepted}
                >
                  Obriši ispit <CgTrash className="ml-3" />
                </Button>
              </div>
            }
          />

          <Button
            onClick={onAccept}
            isLoading={isLoading}
            hide={
              !Utils.hasPermission(loginData?.data?.roles, [ROLES.Moderator]) ||
              !isExamSent
            }
          >
            Odobri
            <RiArrowGoBackFill className="ml-3" />
          </Button>
          <Button
            type="button"
            onClick={() => formRef?.current?.handleSubmit()}
            isLoading={isLoading}
            hide={!isExaminer || data?.status === HuntingExamsStatus.Rejected}
          >
            Sačuvaj
            <FiSave className="ml-3" />
          </Button>
        </>
      }
    >
      <div className="space-y-4">
        <FormFactory
          formFields={examsCreateFields(disableInputs)}
          onSubmit={onSubmit}
          formRef={formRef}
        />
        <div className="flex items-center justify-between">
          <h1 className="text-md">Kandidati</h1>
          {!isExamSent && (
            <Modal
              open={openCreateModal}
              setOpen={setOpenCreateModal}
              triggerButton={
                <Button
                  isLoading={isLoading}
                  hide={!isExaminer || isExamAccepted}
                >
                  Dodaj kandidata
                  <TiPlusOutline className="ml-3" />
                </Button>
              }
              headerTitle="Dodaj kandidata"
              buttonTitle="Dodaj"
              onSubmit={() => candidateCreateFormRef?.current?.handleSubmit()}
              content={
                <FormFactory
                  formFields={createCandidateFields()}
                  onSubmit={onCreateCandidate}
                  formRef={candidateCreateFormRef}
                  validationSchema={CreateCandidateSchema}
                />
              }
            />
          )}
        </div>

        {data?.candidates.length === 0 ? (
          <div className="flex items-center justify-center  rounded-lg border border-gray-200 p-4">
            <RxValueNone size={20} className="text-gray-600" />
            <p className="ml-1 text-xs text-gray-500">Nema dodatih kandidata</p>
          </div>
        ) : (
          <Table
            data={{ data: data?.candidates }}
            columns={tableColumns}
            hideDelete={true}
            onPrint={(candidate) =>
              onGeneratePdf({
                candidateId: candidate?.id,
                withBackground: false,
              })
            }
            onEdit={(item) => {
              setCandidate(item);
              setOpenEditModal(true);
            }}
            hideEdit={!isExaminer}
            actionButtons={actionButtons}
          />
        )}
      </div>

      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni kandidata"
        buttonTitle="Izmeni"
        onSubmit={() => candidateEditFormRef?.current?.handleSubmit()}
        content={
          <FormFactory
            formFields={createCandidateFields(candidate)}
            onSubmit={onEditCandidate}
            formRef={candidateEditFormRef}
            validationSchema={CreateCandidateSchema}
          />
        }
      />
    </PageLayout>
  );
};

export default ExamsEdit;
