import { FieldInputProps, FormikProps } from "formik";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import InputRequiredTooltip from "./InputRequiredTooltip";
import { Button } from "../ui/button";
import { GrPowerReset } from "react-icons/gr";

type DropdownProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  className?: string;
  options: {
    name: string;
    id: number;
  }[];
  callback?: (value: string) => void;
  value?: string;
  showreset?: boolean;
};

const Dropdown = ({
  form,
  field,
  className,
  label,
  options,
  required,
  placeholder,
  disabled,
  callback,
  value,
  showreset,
}: DropdownProps) => {
  const error = form?.errors?.[field?.name!];

  return (
    <div className={className}>
      {label && (
        <div className="flex flex-row items-center space-x-1">
          <label className="mb-2 block text-xs text-label">{label}</label>
          {required && <InputRequiredTooltip label="Polje je obavezno" />}
        </div>
      )}
      <Select
        value={field?.value ? field?.value : value ?? ""}
        onValueChange={(value) => {
          form?.setFieldValue(field?.name!, value);
          callback?.(value);
        }}
      >
        <SelectTrigger
          disabled={disabled}
          className={`${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""}`}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options?.map((option, index: number) => (
            <SelectItem key={index} value={`${option.id}`}>
              {option.name}
            </SelectItem>
          ))}
          {showreset && (
            <>
              <SelectSeparator />
              <Button
                className="w-full"
                variant="secondary"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  form?.setFieldValue(field?.name!, undefined);
                  callback?.("");
                }}
              >
                Resetuj
                <GrPowerReset size={12} className="ml-1" />
              </Button>
            </>
          )}
        </SelectContent>
      </Select>
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
