import { useEffect, useState } from "react";
import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-grounds-reports-fields";
import Utils from "@/common/utils/Utils";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import { ReportsService } from "@/common/API/ReportsService";
import { HuntingGroundReport } from "@/common/types/Reports";
import useSelectedRows from "@/common/hooks/useSelectedRows";
import { Button } from "@/components/ui/button";
import { GrDocumentDownload } from "react-icons/gr";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import useDropdownData from "@/common/hooks/useDropdownData";

const HuntingGroundsReportsList = () => {
  const [data, setData] = useState<HuntingGroundReport | boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginData } = useAuth();

  const { selectedRows, selectAll, onRowSelect, onRowSelectAll } =
    useSelectedRows();

  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: false,
    query,
  });

  const getHuntingGroundsReportsData = async () => {
    if (query.without_hunting_plan === "") {
      delete query.without_hunting_plan;
    }

    const response = await ReportsService.all_hunting_grounds(
      Utils.formatQueryString(query),
    );

    setData(response?.data);
  };

  useEffect(() => {
    getHuntingGroundsReportsData();
  }, [query]);

  const generateOneDocument = async (huntingReport: HuntingGroundReport) => {
    setIsLoading(true);
    const response = await ReportsService.hunting_plan_pdf(
      Utils.formatQueryString(query),
      {
        hunting_ground_ids: [Number(huntingReport?.id)],
      },
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const generateMultipleDocuments = async () => {
    setIsLoading(true);
    const response = await ReportsService.hunting_plan_pdf(
      Utils.formatQueryString(query),
      {
        hunting_ground_ids: selectAll ? "ALL" : selectedRows,
      },
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  return (
    <PageLayout
      title="Lovišta - Izveštaj godišnjeg plana"
      buttons={
        selectedRows?.length !== 0 || selectAll ? (
          <Button
            type="button"
            variant="secondary"
            onClick={generateMultipleDocuments}
            isLoading={isLoading}
            hide={
              !Utils.hasPermission(loginData?.data?.roles, [
                ROLES.Superadmin,
                ROLES.Moderator,
              ])
            }
          >
            Generiši dokument
            <GrDocumentDownload className="ml-3 text-text" />
          </Button>
        ) : (
          <></>
        )
      }
    >
      <Table
        data={data}
        columns={tableColumns(
          selectedRows,
          selectAll,
          onRowSelect,
          onRowSelectAll,
          query,
        )}
        filterFields={filterFields(associations, organizations)}
        filterQueries={filterQueries}
        onPrint={generateOneDocument}
        hideDelete={true}
        hideEdit={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        selectedRows={selectedRows}
        selectAll={selectAll}
      />
    </PageLayout>
  );
};

export default HuntingGroundsReportsList;
