import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { useRef } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

import DocumentLink from "./DocumentLink";
import InputRequiredTooltip from "./forms/InputRequiredTooltip";

type DocumentUploadProps = {
  name?: string;
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  accept?: string;
  files?: any;
  className?: string;
  onUpload?: (file: File) => void;
  required?: boolean;
};

type DocumentUploadFormProps = FieldProps<any> & DocumentUploadProps;

const DocumentUpload = ({
  name,
  field,
  form,
  label,
  accept,
  className,
  onUpload,
  required,
  ...props
}: DocumentUploadFormProps | DocumentUploadProps) => {
  const error = form?.errors?.[field?.name!];
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length > 0) {
      form?.setFieldValue(field?.name!, fileList[0]);
      onUpload?.(fileList[0]);
    }
  };

  return (
    <div className={`${className} relative`}>
      <div className="mb-2 flex flex-row items-center space-x-1">
        <label className="block text-xs text-label">{label}</label>
        {required && <InputRequiredTooltip label="Dokument je obavezan" />}
      </div>
      {!field?.value ? (
        <label
          htmlFor={field?.name ? field?.name : name}
          className="block w-fit cursor-pointer rounded-lg border border-dashed border-gray-300 p-3 text-xs transition-all duration-300 hover:border-gray-400"
        >
          <div className="flex flex-row items-center space-x-2 transition-all duration-300 hover:text-primary">
            <IoCloudUploadOutline size={20} className="text-primary" />
            <div className="text-xs font-normal">Dodaj dokument</div>
          </div>
          <input
            id={field?.name ? field?.name : name}
            {...field}
            {...props}
            type="file"
            accept={accept}
            className="hidden w-auto cursor-pointer text-sm text-primary file:mr-2 file:cursor-pointer file:border-0 file:bg-gray-200 file:px-2 file:py-2 file:text-black file:hover:bg-gray-300"
            onChange={handleChange}
            value={undefined}
            ref={inputRef}
          />
        </label>
      ) : (
        <div>
          <DocumentLink
            title={field?.value?.name}
            hideLabel={true}
            onDelete={() => form?.setFieldValue(field?.name, undefined)}
          />
        </div>
      )}
      <p className="absolute text-sm text-error">
        {error ? (error as string) : ""}
      </p>
    </div>
  );
};

export default DocumentUpload;
