import CONSTANTS from "@/common/config/constants";
import ROLES from "@/common/enums/roles";
import { DropdownData } from "@/common/types/Form";
import { IAssociation } from "@/common/types/HuntingOrders";
import { IUser, Role } from "@/common/types/User";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import TooltipWrapper from "@/components/TooltipWrapper";
import Checkbox from "@/components/forms/Checkbox";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import Input from "@/components/forms/Input";
import PasswordInput from "@/components/forms/PasswordInput";

export const tableColumns = [
  {
    title: "Ime",
    dataIndex: "name",
    key: "name",
    sortOptions: {
      sortDirection: "",
      sortParam: "name",
    },
  },
  {
    title: "Mesto",
    dataIndex: "residence",
    key: "residence",
    sortOptions: {
      sortDirection: "",
      sortParam: "residence",
    },
  },
  {
    title: "Telefon",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Matični broj",
    dataIndex: "unique_identifier",
    key: "unique_identifier",
  },
  {
    title: "Savez",
    dataIndex: ["association", "name"],
    key: ["association", "name"],
    render: ({ association }: { association: IAssociation }) => {
      const a = CONSTANTS.huntingAssociations.find(
        (a) => a?.id === association?.id,
      );

      return association ? (
        <TooltipWrapper
          button={
            <div className="flex flex-row items-center space-x-1">
              <Tag title={a?.name} className={a?.className} />
            </div>
          }
          text={a?.fullName!}
        />
      ) : (
        <Text variant="table">-</Text>
      );
    },
  },
  {
    title: "Korisnik lovišta/Agencija",
    dataIndex: ["organization", "name"],
    key: ["organization", "name"],
    render: ({
      organization,
    }: {
      organization: { id: number; name: string };
    }) => (
      <Text variant="table">{organization ? organization?.name : "-"}</Text>
    ),
  },
  {
    title: "Rola",
    dataIndex: ["role", "id"],
    key: ["role", "id"],
    render: ({ roles }: { roles: Role[] }) => {
      return (
        <div className="flex flex-row items-center space-x-1">
          {roles?.map((role, index) => (
            <Tag
              key={index}
              title={role?.name}
              className={`${role?.id === 8 ? "bg-amber-100 text-amber-500" : "bg-green-100 text-green-500"}`}
            />
          ))}
        </div>
      );
    },
  },
];

export const filterFields = (
  associations?: DropdownData[],
  organizations?: DropdownData[],
  hasAssociation?: boolean,
) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Ime",
        name: "name",
        placeholder: "Pretraži po imenu i prezimenu",
        value: "",
        focus: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Matični broj",
        name: "unique_identifier",
        placeholder: "Pretraži po matičnom broju",
        value: "",
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez/Agencija",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
        hide: hasAssociation,
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta",
        options: organizations ?? [],
        value: "",
        disabled: organizations?.length === 0,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Rola",
        name: "role",
        placeholder: "Izaberi rolu",
        options: CONSTANTS.roleObjects(true),
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  name: "",
  unique_identifier: "",
  association_id: "",
  organization_id: "",
  role: "",
};

export const usersAddFields = (
  assocations?: DropdownData[],
  organizations?: DropdownData[],
  role?: number,
  onRoleChange?: (role?: string) => void,
  getOrganizations?: () => void,
  isPasswordRequired?: boolean,
) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ime i prezime",
        name: "name",
        placeholder: "Ime korisnika",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Email",
        name: "email",
        placeholder: "Unesite email",
        value: "",
        required: true,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lozinka",
        name: "password",
        placeholder: "Unesite lozinku",
        value: "",
        required: isPasswordRequired,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ponovite lozinku",
        name: "password_confirmation",
        placeholder: "Unesite lozinku",
        value: "",
        required: isPasswordRequired,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "JMBG/Broj pasoša",
        name: "unique_identifier",
        placeholder: "Unesite JMBG/Broj pasoša",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Telefon",
        name: "phone",
        placeholder: "Unesite telefon",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Unesite adresu",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Mesto",
        name: "residence",
        placeholder: "Unesite mesto",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Poštanski broj",
        name: "postal_code",
        placeholder: "Unesite poštanski broj",
        value: "",
        required: true,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Rola",
        name: "role_id",
        placeholder: "Izaberite rolu",
        value: "",
        required: true,
        showReset: true,
        options: CONSTANTS.roleObjects(false),
        callback: onRoleChange,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Regionalni/Pokrajinski savez",
        name: "association_id",
        placeholder: "Izaberite savez",
        value: "",
        options: assocations,
        required: true,
        callback:
          [
            ROLES.Superadmin,
            ROLES.Agency,
            ROLES.Inspector,
            ROLES.Moderator,
          ].includes(Number(role)) || Number(role) === -1
            ? () => null
            : getOrganizations,
        hide: [
          ROLES.Superadmin,
          ROLES.Agency,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: DropdownSearch,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberite korisnika lovišta ili agenciju",
        value: "",
        options: organizations,
        required: true,
        disabled: organizations?.length === 0,
        hide: [
          ROLES.Superadmin,
          ROLES.Admin,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: Checkbox,
      className: "col-span-12 mb-3",
      options: {
        label: "Da li je korisnik predsednik komisije?",
        name: "is_examiner",
        placeholder: "Predsednik komisije",
        value: false,
      },
    },
  ];
};

export const usersEditFields = (
  assocations?: DropdownData[],
  organizations?: DropdownData[],
  role?: number,
  onRoleChange?: (role?: string) => void,
  getOrganizations?: () => void,
  isPasswordRequired?: boolean,
  data?: IUser,
) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ime i prezime",
        name: "name",
        placeholder: "Ime korisnika",
        value: data?.name ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Email",
        name: "email",
        placeholder: "Unesite email",
        value: data?.email ?? "",
        required: true,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lozinka",
        name: "password",
        placeholder: "Unesite lozinku",
        value: "",
        required: isPasswordRequired,
      },
    },
    {
      controlType: PasswordInput,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Ponovite lozinku",
        name: "password_confirmation",
        placeholder: "Unesite lozinku",
        value: "",
        required: isPasswordRequired,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "JMBG/Broj pasoša",
        name: "unique_identifier",
        placeholder: "Unesite JMBG/Broj pasoša",
        value: data?.unique_identifier ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Telefon",
        name: "phone",
        placeholder: "Unesite telefon",
        value: data?.phone ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Unesite adresu",
        value: data?.address ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Mesto",
        name: "residence",
        placeholder: "Unesite mesto",
        value: data?.residence ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Poštanski broj",
        name: "postal_code",
        placeholder: "Unesite poštanski broj",
        value: data?.postal_code ?? "",
        required: true,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Rola",
        name: "role_id",
        placeholder: "Izaberite rolu",
        // roles array never has more than 2 roles, one of which is always examiner and the other the actual role of the user
        // if roles array has 1 element, it's always the actual role
        value: String(
          data?.roles?.find((role) => role?.id != ROLES.Examiner)?.id ?? "",
        ),
        required: true,
        showReset: true,
        options: CONSTANTS.roleObjects(false),
        callback: onRoleChange,
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Regionalni/Pokrajinski savez",
        name: "association_id",
        placeholder: "Izaberite savez",
        value: String(data?.association?.id ?? ""),
        options: assocations,
        required: true,
        callback:
          [
            ROLES.Superadmin,
            ROLES.Agency,
            ROLES.Inspector,
            ROLES.Moderator,
          ].includes(Number(role)) || Number(role) === -1
            ? () => null
            : getOrganizations,
        hide: [
          ROLES.Superadmin,
          ROLES.Agency,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: DropdownSearch,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberite korisnika lovišta ili agenciju",
        value: String(data?.organization?.id ?? ""),
        options: organizations,
        required: true,
        disabled: organizations?.length === 0,
        hide: [
          ROLES.Superadmin,
          ROLES.Admin,
          ROLES.Inspector,
          ROLES.Moderator,
        ].includes(Number(role)),
      },
    },
    {
      controlType: Checkbox,
      className: "col-span-12 mb-3",
      options: {
        label: "Da li je korisnik predsednik komisije?",
        name: "is_examiner",
        placeholder: "Predsednik komisije",
        // is true if roles array has role id of examiner
        value:
          data?.roles?.filter((role) => role?.id === ROLES.Examiner)?.length !==
          0,
      },
    },
  ];
};
