const StatusTag = ({
  type,
  title,
}: {
  type: "open" | "in-progress" | "rejected" | "accepted";
  title: string;
}) => {
  switch (type) {
    case "open":
      return (
        <span className="me-2  flex w-fit rounded-lg bg-blue-100 px-2 py-1.5 text-xs font-medium text-blue-500">
          {title}
        </span>
      );
    case "in-progress":
      return (
        <span className="me-2 flex w-fit rounded-lg bg-amber-100 px-2 py-1.5 text-xs font-medium text-amber-500">
          {title}
        </span>
      );
    case "rejected":
      return (
        <span className="me-2 flex w-fit rounded-lg bg-red-100 px-2 py-1.5 text-xs font-medium text-red-500">
          {title}
        </span>
      );
    case "accepted":
      return (
        <span className="me-2 flex w-fit rounded-lg bg-green-100 px-2 py-1.5 text-xs font-medium text-green-500">
          {title}
        </span>
      );
  }
};

export default StatusTag;
