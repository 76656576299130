import { useNavigate } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useRef, useState } from "react";
import { organizationsCreateFields } from "./organizations-fields";
import { OrganizationsService } from "@/common/API/OrganizationsService";
import { OrganizationData } from "@/common/types/Organizations";
import { AssociationData } from "@/common/types/Associations";
import { CreateOrganizationSchema } from "@/common/schemas/Organizations";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import { FiSave } from "react-icons/fi";
import { FormikProps } from "formik";
import useDropdownData from "@/common/hooks/useDropdownData";

const OrganizationsAdd = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("1");

  const { associations } = useDropdownData({ hasAgency: false });
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<OrganizationData>>(null);

  const onSubmit = async (values: OrganizationData | AssociationData) => {
    try {
      setIsLoading(true);

      await OrganizationsService.create({
        ...values,
        type: formType,
      });

      navigate(`/organizations`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      title="Kreiraj korisnika lovišta/agenciju"
      backUrl="./../"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <FormFactory
        formFields={organizationsCreateFields(
          formType,
          setFormType,
          associations,
        )}
        onSubmit={onSubmit}
        validationSchema={CreateOrganizationSchema}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default OrganizationsAdd;
