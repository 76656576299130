import { DropdownData } from "@/common/types/Form";
import Dropdown from "@/components/forms/Dropdown";

export const filterFields = (seasons?: DropdownData[]) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovna godina",
        name: "season",
        placeholder: "Izaberite lovnu godinu",
        value: "",
        required: false,
        options: seasons ?? [],
      },
    },
  ];
};

export const filterQueries = {
  season: "",
};
