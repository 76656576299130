import { DropdownData } from "@/common/types/Form";
import { HuntingCardReport } from "@/common/types/Reports";
import Dropdown from "@/components/forms/Dropdown";

export const tableColumns = (data?: HuntingCardReport) => {
  return [
    {
      title: "Korisnik lovišta",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mesto",
      dataIndex: "residence",
      key: "residence",
    },
    {
      title: `Broj turističkih lovnih karata: ${data?.tourist_card_sum}`,
      dataIndex: "tourist_card_count",
      key: "tourist_card_count",
    },
    {
      title: `Broj članskih lovnih karata: ${data?.member_card_sum}`,
      dataIndex: "member_card_count",
      key: "member_card_count",
    },
    {
      title: `Broj ukupnih lovnih karata: ${data?.total_card_sum}`,
      dataIndex: "total_card_count",
      key: "total_card_count",
    },
  ];
};

export const filterFields = (
  associations?: DropdownData[],
  seasons?: DropdownData[],
) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-3",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez/agenciju",
        options: associations ?? [],
        value: "",
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-3",
      options: {
        label: "Lovna sezona",
        name: "season",
        placeholder: "Izaberite lovnu sezonu",
        value: "",
        options: seasons,
        hide: seasons?.length === 0,
      },
    },
  ];
};

export const filterQueries = {
  association_id: "",
  season: "",
};
