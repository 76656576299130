const TableSortHeaderItem = ({
  onClick,
  title,
  icon,
}: {
  title: string;
  icon?: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <div
      className="flex cursor-pointer flex-row items-center"
      onClick={onClick}
    >
      {title}
      <div>{icon}</div>
    </div>
  );
};

export default TableSortHeaderItem;
