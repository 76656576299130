import { Role } from "../types/User";

const FormUtils = {
  generateFormData: (formValues: any, fileProps: string[] = []) => {
    const fd: FormData = new FormData();

    fileProps?.forEach((prop: string) => {
      fd.append(prop, formValues[prop]);
      delete formValues[prop];
    });

    fd.append("data", JSON.stringify(formValues));

    return fd;
  },
  generateDropdownData: (dropdownItems?: { name: string; id: number }[]) =>
    dropdownItems?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    }),
  formatUserRoles: (roles?: Role[]) => roles?.map((item) => item?.id),

  checkAreValuesEmpty(obj: Record<string, any>, obj_key: string) {
    for (const key in obj) {
      if (key !== obj_key && obj[key] !== 0) {
        return true;
      }
    }
    return false;
  },

  appendDataToFormData(obj: Record<string, any>, formData: FormData) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach((el: any) => formData.append(`${key}[]`, el));
        } else {
          formData.append(key, obj[key]);
        }
      }
    }
  },

  appendFilesToRequest(fileList: File[], fd: FormData, fdKey: string) {
    fileList.forEach((file) => {
      if (file?.lastModified) {
        fd.append(fdKey, file);
      }
    });
  },
};

export default FormUtils;
