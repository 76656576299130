import { FieldInputProps, FieldProps, FormikProps } from "formik";
import { LuDownload } from "react-icons/lu";
import ConfirmationPopover from "./ConfirmationPopover";
import TooltipWrapper from "./TooltipWrapper";
import { CgTrash } from "react-icons/cg";
import Utils from "@/common/utils/Utils";

type DocumentLinkProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  url?: string;
  label?: string;
  title?: string;
  className?: string;
  onDelete?: () => void;
  hideDelete?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
};

type DocumentLinkFormProps = FieldProps<any> & DocumentLinkProps;

const DocumentLink = ({
  url,
  title,
  label,
  className,
  onDelete,
  hideDelete,
  hideLabel,
  disabled,
  ...props
}: DocumentLinkFormProps | DocumentLinkProps) => {
  return (
    <div className={` ${className}`}>
      {!hideLabel && (
        <label className="mb-2 block text-xs text-gray-900">{label}</label>
      )}
      <TooltipWrapper
        button={
          <div className="cursor-pointer rounded-lg border border-gray-300 p-3 transition-all duration-300 hover:border-gray-400">
            <div
              className="flex flex-row items-center justify-between"
              onClick={() => {
                Utils.openDocument(url);
              }}
            >
              <div className="flex items-center">
                <LuDownload size={16} className="mr-2 text-primary" />
                <span className="cursor-pointer text-xs font-normal">
                  {title}
                </span>
              </div>
              {!(hideDelete || disabled) && (
                <div className="flex" onClick={(e) => e.stopPropagation()}>
                  <ConfirmationPopover
                    title="Da li ste sigurni da želite da obrišete dokument?"
                    button={
                      <div>
                        <TooltipWrapper
                          button={
                            <div>
                              <CgTrash
                                size={15}
                                className="self-center text-gray-400 transition-all duration-300 hover:text-red-500"
                              />
                            </div>
                          }
                          text="Obriši"
                        />
                      </div>
                    }
                    onDelete={onDelete}
                  />
                </div>
              )}
            </div>
          </div>
        }
        text="Pogledaj dokument"
      />
    </div>
  );
};

export default DocumentLink;
