import { Outlet } from "react-router-dom";
import authBg from "../../common/static/auth-bg.jpeg";
import lssLogo from "../../common/static/lss-logo.png";

const AuthLayout = () => {
  return (
    <main className="flex bg-gray-50">
      <div className="flex h-screen w-screen flex-col content-center items-center justify-center space-y-4 lg:w-1/2 lg:flex-col">
        <img src={lssLogo} className="h-28 w-28" />
        <Outlet />
      </div>
      <div
        style={{
          backgroundImage: `url(${authBg})`,
        }}
        className="hidden h-screen bg-cover bg-center lg:block lg:w-1/2 lg:self-start"
      ></div>
    </main>
  );
};

export default AuthLayout;
