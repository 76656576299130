import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "@/components/table/Table";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./organizations-fields";
import { OrganizationsService } from "@/common/API/OrganizationsService";
import { OrganizationDataResponse } from "@/common/types/Organizations";
import Utils from "@/common/utils/Utils";
import ROLES from "@/common/enums/roles";
import { useAuth } from "@/common/context/AuthProvider";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import useDropdownData from "@/common/hooks/useDropdownData";

const OrganizationsList = () => {
  const [data, setData] = useState<OrganizationDataResponse | null>(null);

  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });

  const { associations, getAssociations } = useDropdownData({
    hasAgency: true,
    query,
  });

  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);
  const hasAssociation = !!loginData?.data?.association;
  const navigate = useNavigate();

  const getOrganizationsData = async () => {
    const res = await OrganizationsService.all(Utils.formatQueryString(query));

    setData(res?.data);
  };

  const onDelete = async (id?: string) => {
    await OrganizationsService.delete(id);
    getOrganizationsData();
  };

  useEffect(() => {
    getAssociations();
    getOrganizationsData();
  }, [query]);

  return (
    <PageLayout title="Korisnici lovišta i agencije" showCreateButton={isAdmin}>
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(associations, hasAssociation)}
        filterQueries={filterQueries}
        onEdit={(item) => navigate(`/organizations/${item?.id}`)}
        onDelete={onDelete}
        hidePrint={true}
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Moderator,
          ])
        }
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default OrganizationsList;
